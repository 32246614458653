import MyProLayout from "../../../components/MyProLayout";
import {ActionType, DragSortTable, ProCard} from "@ant-design/pro-components";
import FetchUtil from "../../../utils/FetchUtil";
import React, {useEffect, useRef, useState} from "react";
import {Button, Menu, message} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import {infoColumns} from "./infoColumns";
import {useParams} from "react-router-dom";
import {BaseInfoDo} from "../../../model/BaseInfoDo";
import InfoEditor from "./InfoEditor";
import {showIcon} from "../../../components/MyUploadIcon";

const BaseInfo = (props: any) => {

    // const [formRef] = Form.useForm();
    const actionRef = useRef<ActionType>();
    const editorRef = useRef();
    const {mode, group, type, cate} = useParams();

    const [mainQuery, setMainQuery] = useState<any>();
    const [mainData, setMainData] = useState<BaseInfoDo[]>([]);
    const [childrenMenu ,setChildrenMenu]=useState<any[]>();
    const [openKeys, setOpenKeys] = useState<string[]>();
    const [selectKey,setSelectKey] =useState<string>();
    const [columns, setColumns] =useState<any[]>([])

    useEffect(()=> {
        loadChildrenMenu().then();
        setMainQuery({
            mode: mode,
            group: group,
            groupId: group,
            infoType: type,
            categoryId: cate
        })
    }, [props]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadChildrenMenu = async () => {
        let keys:string[]=[];
        let first:any=undefined;
        const getMenu: (data1: any[]) => any[] = (data1: any[]) => {
            return data1?.map((r: any) => {
                const r1 = {
                    label: r.paramName,
                    key: r.paramId,
                    value: r.paramId,
                    icon: showIcon(r.icon),
                    children: getMenu(r.children)
                }
                if (r.children && r.children.length > 0) {
                    keys.push(r.paramId.toString());
                } else if (!first) {
                    first = r1;
                }
                return r1;
            })
        }
        if (group) {
            const res = await FetchUtil.awaitFetch("/adminApi/param/listTree", {id: group});
            if (res.code === 200) {
                const myMenu = getMenu(res.data);
                setChildrenMenu(myMenu);
                setOpenKeys(keys);
                menuClick(first, myMenu);
                // setSelectKey(first);
            }
        }else {
            const cols = infoColumns(
                {
                    // mode: "default",
                    // type: undefined,
                    editor: openEdit,
                    deleter: deleteRecord,
                })
            setColumns(cols);
        }
    }
    const menuClick = (e: any, mData?: any[]) => {
        const myMenu = mData || childrenMenu;
        const getItem = (date?:any[], id?:string) => {
            let item:any;
            date?.forEach(r=>{
                if (r.key === id) {
                    item =r;
                }
                if (!item) {
                    item = getItem(r.children, id);
                }
            })
            return item;
        }
        setSelectKey(e.key.toString());
        const item = getItem(myMenu, e.key);
        console.log(item)
        const cols = infoColumns(
            {
                mode: mode,
                type: e.key || item.paramCode,
                editor: openEdit,
                deleter: deleteRecord,
                typeEnum: myMenu
            })
        setColumns(cols);
        setMainQuery({...mainQuery, infoType: e.key});
        actionRef.current?.reload();
    }

    const openEdit = (r?: any) => {
        console.log(r);
        if (!r) {
            r = {
                groupId: group,
                infoType: type,
                categoryId: cate
            }
        }

        (editorRef.current as any).openEditor(r);
        // setShowEdit(true);
    }

    const deleteRecord = async (record_key: number) => {
        const res = await FetchUtil.awaitFetch('/adminApi/info/delete', [record_key])
        if (res.code === 200) {
            message.success({content: '记录已删除成功！'});
            actionRef.current?.reload();
        }
    }

    const onDragSortEnd = async (beforeIndex: number, afterIndex: number, newDataSource: any[]) => {
        console.log(beforeIndex, afterIndex, newDataSource)
        setMainData(newDataSource);
        const records = newDataSource.map((r, i) => ({
            id: r.id,
            sortIndex: i
        }))
        const res = await FetchUtil.awaitFetch('/adminApi/info/edit', records)
        if (res.code === 200) {
            console.log(res)
            message.success({content: '排序保存成功！'});
            actionRef.current?.reload();
        }
    }

    return (
        <MyProLayout siderCollapse={!!childrenMenu}>
            <ProCard ghost >
                {childrenMenu &&
                <ProCard ghost colSpan={"240px"} style={{marginLeft: -16}}>
                    <Menu
                        mode={"inline"}
                        style={{padding:0, borderRadius: 8}}
                        items={childrenMenu}
                        openKeys={openKeys}
                        selectedKeys={[selectKey||'']}
                        onOpenChange={(keys)=>setOpenKeys(keys)}
                        onClick={menuClick}
                    />
                </ProCard>
                }
                <ProCard ghost>
                    <DragSortTable
                        rowKey={"id"}
                        dragSortKey={"_id"}
                        onDragSortEnd={onDragSortEnd}
                        toolbar={{
                            actions: [
                                <Button type='primary' onClick={() => openEdit()}
                                        key='0'><PlusCircleOutlined/> 新建</Button>
                            ],
                        }}
                        actionRef={actionRef}
                        columns={columns}
                        cardBordered={true}
                        // params={{infoType: type,
                        //     categoryId: cate,}}
                        dataSource={mainData}
                        request={async (
                            params,
                        ) => {
                            const infoQuery = {
                                ...mainQuery,
                                ...params,
                            }
                            if (params.title || params.resume || params.mappingCode) {
                                infoQuery.infoType = undefined;
                            }
                            const query: any = {
                                info: infoQuery,
                                page: {pageNo: params.current, pageSize: params.pageSize}
                            };
                            const res = await FetchUtil.awaitFetch('/adminApi/info/list', query);
                            setMainData(res.data.list);
                            return {
                                success: true,
                                data: res.data.list,
                                total: res.data.total,
                            };
                        }}
                        // search={{span: 6}}
                    />
                </ProCard>
            </ProCard>

            <InfoEditor ref={editorRef}
                        columns={columns}
                        // typeData={childrenMenu}
                        callback={() => actionRef.current?.reload()}
                        multiLanguage={true}/>
        </MyProLayout>
    )
}
export default BaseInfo