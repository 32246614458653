import FetchUtil from "../utils/FetchUtil";
// import React from "react";
// import {useAppContext} from "../AppContaxt";

export default class LanguageData {

    static load = async () => {

        const res = await FetchUtil.awaitFetch("/adminApi/language/list", {})
        if (res.code === 200) {
            const data = res.data.list;
            const defaultLang = data?.find((r: any) => r.defaultLang === 1)?.langCode || "en";
            const langData = data.map((r: any) => {
                return {
                    langCode: r.langCode,
                    langName: r.langName,
                    // icon: <img src={r.icon} style={{height: 12}} alt=""/>,
                    key: r.langCode,
                    value: r.langCode,
                    label: r.langName
                }
            })
            // Do not set to store, language data will save in appState
            // store.set("defaultLang", defaultLang);
            return {langData: langData, defaultLang: defaultLang};
        }
        return {
            langData: [{langCode: "en", langName: "English", key: "en", value: "en", label: "English",}],
            defaultLang: "en"
        };
    }
}