import React, {  useState} from 'react';
import {
    LoginForm,
    ProCard,
    ProFormText,
    ProLayout
} from "@ant-design/pro-components";
import dLogo from "../images/game.svg"
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {Checkbox, message} from "antd";
import moment from "moment/moment";
import store from "store";
import cookie from "react-cookies";
// import {useNavigate} from "react-router";
import FetchUtil from "../utils/FetchUtil";


const Login = () => {

    // const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const getPowerMenu = async () => {
        const res = await FetchUtil.awaitFetch('/adminApi/function/tree', {id: 3})
        if (res.code === 200) {
            const menus = res.data;
            store.set('login_power', menus);
            window.setTimeout(() => {
                window.location.href = "/#/dashboard";
                window.location.reload();
                // navigate("/dashboard")
            }, 500)
        }
    }

    const onFinish = async (values: Record<string, any>) => {
        setLoading(true);
        console.log(values);
        console.log(moment().add(7, 'day'));

        const post = {
            loginName: values.username,
            loginPassword: values.password
        }
        const res = await FetchUtil.awaitFetch('/authApi/login/login', post);
        if (res.code === 200) {
            store.set("login_info", res.data);
            if (values.remember) {
                cookie.save(
                    "isLogin",
                    "true",
                    {path: "/",
                        expires:  new Date(moment().add(7, 'day').format('YYYY-MM-DD HH:mm:ss'))
                    });
            } else {
                cookie.save("isLogin", "true", {path: "/"});
            }
            message.success('登录成功，即将跳转');
            getPowerMenu().then();
        }else {
            message.error(res.msg);
        }
        setLoading(false);
    };

    return (
        <ProLayout layout={"top"} logo={undefined} title={false}>
            <ProCard boxShadow style={{width: 512, margin: "auto"}}>
                <LoginForm logo={dLogo}
                           title={"EasyGameNow"}
                           subTitle={" "}
                           actions
                           message={""}
                           onFinish={onFinish}
                           loading={loading}
                >
                    <ProFormText
                        name="username"
                        fieldProps={{
                            size: 'large',
                            prefix: <UserOutlined className={'prefixIcon'}/>,
                        }}
                        placeholder={'用户名: admin or user'}
                        rules={[{required: true, message: '请输入用户名!',}]}
                    />
                    <ProFormText.Password
                        name="password"
                        fieldProps={{
                            size: 'large',
                            prefix: <LockOutlined className={'prefixIcon'}/>,
                        }}
                        placeholder={'密码: Your password'}
                        rules={[{required: true, message: '请输入密码！',}]}
                    />

                    <div style={{marginBlockEnd: 24,}}>
                        <Checkbox name="autoLogin" checked>
                            自动登录
                        </Checkbox>
                        <a style={{float: 'right',}}>
                            忘记密码
                        </a>
                    </div>
                </LoginForm>
            </ProCard>
        </ProLayout>
    )
}

export default Login