import {Select, Tag} from "antd";
import React from "react";

export default class DataUtil {

    static formatSendStatus(s?: number) {
        return s === undefined ? ""
            : s === 0 ? <Tag>等待</Tag>
                : s === 1 ? <Tag color="blue">发送中</Tag>
                    : s === 2 ? <Tag color="green">完成</Tag>
                        : s === 3 ? <Tag color="orange">暂停</Tag>
                            : s === 9 ? <Tag color="red">失败</Tag>
                                : s < 0 ? <Tag color="orange">错误{s}</Tag>
                                : s.toString()
    }

    /**
     * 格式化数字（千分符+小数）
     * @param v 数值
     * @param d 保留小数
     */
    static formatNumber(v: number, d: number) {
        if (v == null)
            return "";
        return (v.toFixed(d) + '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
    }

    /**
     * 数据纵列转换
     * @param jsonData
     * @param idField
     * @param colField
     * @param valueField
     * @param emptyValue
     */
    static row2col(jsonData: any, idField: string, colField: string, valueField: string, emptyValue: string) {
        let result: any[] = [],         //存储返回的数据
            idIndexData: number[] = [], //存储id在数组中的信息(位置)
            resultColumns: string[] = [],     //存储列名数据
            curRecord = null;           //存储当前数据

        let idFields = idField.split(',');
        //let colFields = colField.split(',');

        // 循环整个JSON数组：[{...},{...},{...},...]
        for (let idx = 0; idx < jsonData.length; idx++) {

            // 当前json数据对象
            let cdata = jsonData[idx];

            // 根据主键值，查找到结果数组中的索引号
            let idValue: any = '';
            for (let idf in idFields) {
                idValue += cdata[idFields[idf]];
            }
            let num = idIndexData[idValue]; //获取存储该id的数组索引号
            if (num != null) {
                curRecord = result[num];
            } else {
                // 初始化数据时保持完整的结构信息 避免因为缺乏数据，缺乏指定的列数据
                curRecord = {};
            }


            // 指定的colFields列下的数据作为y轴，则取出该列的数据作为y轴即可
            // for (var i in colFields) {
            //     var key = colFields[i];

            // 获取到colField的值，作为列名
            var value = cdata[colField];
            curRecord[value] = cdata[valueField];

            // 存储列名
            if (resultColumns.indexOf(value) === -1)
                resultColumns.push(value);
            //     break;
            // }

            // 除数据内容外，还需要添加主键数据
            //curRecord[idField] = idValue;
            for (let idf in idFields) {
                curRecord[idFields[idf]] = cdata[idFields[idf]];
            }

            // 对象若为新建 则新增进数组
            if (num == null) {
                idIndexData[idValue] = result.push(curRecord) - 1;
            }
        }

        // 数据检查 由于是将行数据作为列名，则可能会存在部分行缺少其他列数据，若缺少，则指定默认值
        for (var i2 in result) {
            for (var name in resultColumns) {
                if (!result[i2].hasOwnProperty(name))
                    result[i2][name] = emptyValue;
            }
        }
        return {columns: resultColumns, data: result};
    }

    static findMaxMin(data: any, field: string) {
        try {
            let maxValue = data[0][field];
            let minValue = data[0][field];
            let maxObj = data[0];
            let minObj = data[0];
            for (const d of data) {
                if (d[field] > maxValue) {
                    maxValue = d[field];
                    maxObj = d;
                }
                if (d[field] < minValue) {
                    minValue = d[field];
                    minObj = d;
                }
            }
            return {max: maxObj, min: minObj};
        } catch {
            return {max: {[field]: 0}, min: {[field]: 0}};
        }
    }

    static selectOptionsRender = (arr: any[], code: string, name: string, excode?: string) => {
        const {Option} = Select;
        return (
            arr ? arr.map((item) => {
                return (
                    <Option key={item[code]} value={item[code]} excode={excode ? item[excode] : ''}>
                        {item[name]}
                    </Option>)
            }) : null
        )
    }

    /**
     * 为树状结构清理数据
     * @param data
     * @param titleField
     * @param keyField
     */
    static treeDataClean = (data: any, titleField?: string, keyField?: string) => {
        const cleanData = (data: any) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].children && data[i].children.length === 0) {
                    data[i].children = undefined;
                } else if (data[i].children && data[i].children.length > 0) {
                    data[i].children = cleanData(data[i].children);
                }
                if (titleField) {
                    data[i].title = data[i][titleField];
                }
                if (keyField) {
                    data[i].key = data[i][keyField];
                }
            }
            return data;
        }
        return cleanData(data);
    }

    /**
     * 判断树状节点是否为终结点
     * @param fid
     * @param funData
     * @param keyField
     */
    static isEndpoint = (fid: number, funData: any[], keyField?: string) => {
        const key = keyField || "key";
        let rtn = false;
        funData.forEach((item: any) => {
            if (rtn)
                return;
            if (fid === item[key]) {
                rtn = item.children === undefined || item.children.length === 0;
            } else {
                if (item.children === undefined || item.children.length === 0) {
                    // return false;
                } else {
                    rtn = DataUtil.isEndpoint(fid, item.children, key);
                }
            }
        })
        return rtn;
    }

    static toLine = (name: string) => {
        return name.replace(/([A-Z])/g, "_$1").toLowerCase();
    }
    static sortToOrderBy = (sort?: any) => {
        if (!sort) {
            return "";
        }
        let sortKeys = Object.keys(sort);
        if (sortKeys.length === 0) {
            return "";
        }
        let key = sortKeys[0];
        let val = sort[key] || "";
        return this.toLine(key) + " " + val.replace("end", "");
    }
}