import {DrawerForm, ProFormDigit, ProFormSwitch, ProFormText, ProFormTextArea} from "@ant-design/pro-components";
import {Form, message} from "antd";
import {forwardRef, useImperativeHandle, useState} from "react";
import {BaseInfoDo} from "../../../model/BaseInfoDo";
import FetchUtil from "../../../utils/FetchUtil";
import FormUtil from "../../../utils/FormUtil";
import MyUploadImage from "../../../components/MyUploadImage";

const PayEditor = (props : any, ref: any) => {

    const [formRef] = Form.useForm();
    const [openForm, setOpenForm] = useState(false);
    const [info, setInfo] = useState<BaseInfoDo | undefined>({});

    let isChanged = false;

    useImperativeHandle(ref, () => {
        return {
            openEditor: (r: any) => openEditor(r)
        }
    });
    const openEditor = async (r: any) => {
        const res = await FetchUtil.awaitFetch('/mallApi/payChannel/detail', {id: r.id});
        if (res.code === 200) {
            console.log(res)
            setInfo(res.data);
            formRef.setFieldsValue(res.data);
            setOpenForm(true);
            isChanged = false;
        } else {
            message.error(res.msg);
        }
    }

    const closeEditor = () => {
        const doClose = (open: boolean) => {
            setOpenForm(open);
            setInfo({});
        }
        FormUtil.close(isChanged, doClose);
    }

    const submitForm = async (values: any) => {
        console.log(values);
        values.payStatus = values.payStatus ? 1 : 0;
        values.topping = values.topping ? 1 : 0;
        const res = await FetchUtil.awaitFetch('/mallApi/payChannel/edit', [values]);
        if (res.code === 200) {
            isChanged = false;
            closeEditor();
            message.success("保存成功！")
            if (props.callback) {
                props.callback();
            }
        }

    }
    // const required: any = {formItemProps: {rules: [{required: true}]}};
    const colProps12: any = {colProps: {span: 12}, labelCol: {span: 8}};
    return (
        <DrawerForm
            width={666}
            form={formRef}
            open={openForm}
            colon={false}
            labelCol={{span: 4, offset: 0}}
            title={"支付渠道"}
            layout="horizontal"
            drawerProps={{onClose: closeEditor, forceRender: true}}
            onFinish={submitForm}
            onChange={() => isChanged = true}
            grid={true}
        >
            <ProFormText name={"id"} hidden/>
            <MyUploadImage name={"image"} label={"主图"} value={info?.image || ""} formRef={formRef}/>
            <ProFormText name={"payName"} label={"名称"}/>
            <ProFormText name={"payCode"} label={"Code"}/>
            <ProFormText name={"feeRate"} label={"费率%"} {...colProps12} />
            <ProFormDigit name={"feeFixed"} label={"固定费用"} {...colProps12} />
            <ProFormSwitch name={"payStatus"} label={"启用"} {...colProps12} />
            <ProFormSwitch name={"topping"} label={"推荐"} {...colProps12} />
            <ProFormTextArea name={"properties"} label={"参数配置"}/>
        </DrawerForm>
    )
}
export default forwardRef(PayEditor)