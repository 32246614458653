import MyProLayout from "../components/MyProLayout";
import {Empty} from "antd";

const Page404 = () => {
    return (
        <MyProLayout>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                   description={<span style={{fontFamily: "Bahnschrift, Courier New"}}>
                       <span style={{color: "#888",fontSize:16}}>
                       404 |</span> NOT FOUND </span>}/>
        </MyProLayout>
    )
}
export default Page404