import React, {lazy, useEffect, useState} from "react";
import { Navigate, RouteObject} from "react-router-dom";

import ChangePass from "../pages/system/changePass";
import Dashboard from "../pages/dashboard";

import Order from "../pages/orders/order";

import Edm from "../pages/mall/edm";
import Exchange from "../pages/mall/exchange";
import PayChannel from "../pages/mall/payChannel";

import Params from "../pages/system/params";
import Function from "../pages/system/function";
import BaseInfo from "../pages/system/baseInfo";
import AliSecurity from "../pages/system/aliSecurity";
import User from "../pages/system/user";
import Role from "../pages/system/user/role";


import {useRoutes} from "react-router";
import store from "store";
import LanguageData from "../api/LanguageData";
import {useAppContext} from "../AppContaxt";
import MenuRouterData from "../api/MenuRouterData";
import Login from "../pages/login";
import Page404 from "../pages/page404";
import Page403 from "../pages/page403";
import Log2 from "../pages/system/log/log2";

export const Modules = {
    Dashboard: Dashboard,

    Category:       lazy(() => import("../pages/games/category")),
    Item:           lazy(() => import("../pages/games/item")),
    // Coefficient: PriceFx,
    OrderAllocate:  lazy(() => import("../pages/games/setting/orderAllocate")),
    PriceFx:        lazy(() => import("../pages/games/setting/priceFx")),
    // PriceFxCrown:   lazy(() => import("../pages/games/setting/priceFxCrown")),

    Log:            lazy(() => import("../pages/system/log")),
    Log2:           lazy(() => import("../pages/system/log/log2")),
    LogCrawler:     lazy(() => import("../pages/system/log/crawler")),

    SeoList:    lazy(() => import("../pages/mall/seo/list")),
    SeoTask:    lazy(() => import("../pages/mall/seo/index")),
    SeoLog:     lazy(() => import("../pages/mall/seo/SeoLog")),

    BaseInfo: BaseInfo,
    Edm: Edm,
    Exchange: Exchange,
    PayChannel: PayChannel,

    Order: Order,

    AliSecurity: AliSecurity,
    Params: Params,
    ChangePass: ChangePass,
    Function: Function,
    User: User,
    Role: Role,
    ManagerPublish: lazy(() => import("../pages/system/manager")),

} as any

export const MyRouter = function () {

    const {updateAppState} = useAppContext();
    const [myRouter, setMyRouter] = useState<RouteObject[]>([]);

    const loadInitData = async () => {
        console.log("MyRouter => useEffect[]")
        const lang =await loadLanguage()
        const rout = await loadMenuRouter()
        updateAppState({...lang, ...rout});
    }

    /***
     * Move initLanguage here because it must be contained in a <AppProvider>
     */
    const loadLanguage = async () => {
        const lang = await LanguageData.load();
        if (lang) {
            return lang;
        }else {
            return {};
        }
    }
    const loadMenuRouter = async () => {
        let routes: RouteObject[]
        const login_info = store.get('login_info');
        if (login_info?.accessToken) {
            try {
                const menu = await MenuRouterData.load();
                menu.push({path: "", element: <Navigate to="/dashboard"/>})

                routes = [
                    {path: "/login", element: <Login/>},
                    {path: "/page404", element: <Page404/>},
                    {path: "/page403", element: <Page403/>},
                    {path: "/dashboard", element: <Dashboard/>},
                    {path: "/changePass", element: <ChangePass/>},
                    {path: "/", children: menu},
                    {path: "/system/log/crawler/:logType?", element: <Log2/>},
                    {path: "*", element: <Navigate to="/page404"/>}
                ];
            } catch {
                routes = [{path: "*", element: <Login/>},]
            }
        } else {
            routes = [{path: "*", element: <Login/>},]
        }
        setMyRouter(routes);
        return {menuRouter: routes};
    }

    useEffect(() => {
        loadInitData().then();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    console.log("MyRouter => ", myRouter)
    return useRoutes(myRouter);
}
