import {Divider, Form, message,} from "antd";
import { GlobalOutlined} from "@ant-design/icons";
import React, {forwardRef, useImperativeHandle, useState} from "react";
import {DrawerForm, ProFormText} from "@ant-design/pro-components";
import FetchUtil from "../../../utils/FetchUtil";
import {FunctionDo} from "../../../model/FunctionDo";
import FormUtil from "../../../utils/FormUtil";
import MyUploadIcon from "../../../components/MyUploadIcon";
import {useAppContext} from "../../../AppContaxt";

const FunctionEditor = (props : any, ref: any) => {

    const [formRef] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [editing, setEditing] = useState<FunctionDo>();

    let isChanged = false;
    const {appState} = useAppContext();
    const langData: any = appState.langData;

    useImperativeHandle(ref, () => {
        return {
            openEditor: (r: any) => openEditor(r)
        }
    });

    const openEditor = async (r: any) => {
        setLoading(true);
        r.functionName = r.functionNameOrig;
        setEditing(r);
        formRef.resetFields();
        formRef.setFieldsValue(r);

        const res = await FetchUtil.awaitFetch("/adminApi/language/multiList", {type: "function", id: r.functionId})
        if (res.code === 200) {
            res.data.list?.forEach((r: any) => {
                formRef.setFieldsValue({[`lang${r.langCode}`]: r.langContent})
            })
            setLoading(false);
        }
        setOpenForm(true);
    }

    const closeEditor = () => {
        const doClose = (open: boolean) => {
            setOpenForm(open);
            setEditing({} as FunctionDo);
        }
        FormUtil.close(isChanged, doClose);
    }

    const submitForm = async (values: any) => {
        console.log(values);
        values.functionId = editing?.functionId;
        const res = await FetchUtil.awaitFetch("/adminApi/function/edit", [values]);
        if (res.code !== 200) {
            message.error({content: res.msg});
            return;
        }

        const langs: any[] = [];
        langData?.forEach((r: any) => {
            langs.push({
                itemType: "function",
                itemId: editing?.functionId,
                langCode: r.langCode,
                langContent: values[`lang${r.langCode}`] || ""
            })
        })
        console.log(langs);
        const res2 = await FetchUtil.awaitFetch("/adminApi/language/multiEdit", langs);
        if (res2.code === 200) {
            message.success("保存成功！").then();
            setOpenForm(false);
            if (props.callback) {
                props.callback();
            }
        } else {
            message.error("保存失败！ " + res2.msg).then();
        }
    }

    return (
        <DrawerForm
            loading={loading}
            width={666}
            open={openForm}
            colon={false}
            labelCol={{span: 4, offset: 0}}
            title={"菜单编辑"}
            layout="horizontal"
            drawerProps={{onClose: closeEditor, forceRender:false}}
            form={formRef}
            onFinish={submitForm}
        >
            <ProFormText name='functionId' hidden />
            <ProFormText name='parentId' hidden />
            <ProFormText name="functionName" label="菜单"/>
            <ProFormText name="pageUrl" label="路径"/>
            {/*<ProFormText name="icon" label="图标"/>*/}
            <MyUploadIcon name={"icon"} label={"图标"} value={editing?.icon||""} formRef={formRef} />
            <ProFormText name="functionInterface" label="Interface"/>

            <Divider orientation="left"><GlobalOutlined/> 多语言设置</Divider>
            {langData?.map((r: any, i: number) =>
                <ProFormText key={i} name={`lang${r.value}`} label={r.label}/>
            )}
        </DrawerForm>
    )
}
export default forwardRef(FunctionEditor)