
import React, { useRef, useState} from "react";
import moment from "moment";
import {PlusCircleOutlined,FormOutlined, DeleteOutlined, SearchOutlined
} from "@ant-design/icons";
import {EdmTaskDo} from "../../../model/EdmTaskDo";
// import ReactDOM from "react-dom";
import {Button, Divider, message, Popconfirm, Space} from "antd";
import MyProLayout from "../../../components/MyProLayout";
import {ActionType, ProTable} from "@ant-design/pro-components";
import FetchUtil from "../../../utils/FetchUtil";
import EdmLog from "./EdmLog";
import EdmEditor from "./EdmEditor";
import DataUtil from "../../../utils/DataUtil";
// import FormUtil from "../../../utils/FormUtil";


const Edm =()=> {

    const actionRef = useRef<ActionType>();
    const formRef = useRef();
    const logsRef = useRef();

    const [openLog, setOpenLog] = useState(false);
    const [logTaskId, setLogTaskId] = useState(0);

    // const changeForm = (change: boolean, values: any) => {
    //     isChanged = true;
    //     console.log("changeForm", values);
    //     if (values.sendTime && values.intervalMin && values.intervalMax && values.edmToList) {
    //         const mailCount = JSON.parse(values.edmToList).total;
    //         const processTime = (values.intervalMin + values.intervalMax) / 2 * mailCount
    //         ;
    //         //setFinishTime(moment(values.sendTime).add(processTime,"seconds").format("yyyy-MM-DD HH:mm:ss"))
    //         ReactDOM.render(<span className="blue strong">
    //                 {moment(values.sendTime).add(processTime, "seconds").format("yyyy-MM-DD HH:mm:ss")}
    //                 （邮件数：{mailCount}）
    //             </span>,
    //             document.getElementById("EdmPlanTime"))
    //     }
    // }

    const openLogs = (r?: EdmTaskDo) => {
        // setLogTaskId(r.id);
        // setOpenLog(true);

        (logsRef.current as any).openEditor(r);
    }
    const openEdit = (r?: EdmTaskDo) => {
        // console.log(r);
        // if (!r) {
        //     r = {intervalMin: 10, intervalMax: 20} as EdmTaskDo;
        //     setEditingData(r);
        //     setOpenEditor(true);
        // } else {
        //     const res = await FetchUtil.awaitFetch("/mallApi/edm/detail", {id: r.id});
        //     if (res.code == 200) {
        //         const r = res.data;
        //         // console.log(r);
        //         r.sendTime = r.sendTime == null || r.sendTime == "" ? "" : moment(r.sendTime)
        //         setEditingData(r);
        //         setOpenEditor(true);
        //         changeForm(false, r);
        //     }
        // }
        (formRef.current as any).openEditor(r);
    }

    const columns: any[] = [
        {title: "ID", dataIndex: "id", align: "center" as "center", width: 40},
        {
            title: 'EDM任务标题',
            dataIndex: 'taskTitle',
            width: 150,
            render: (v: string, r: EdmTaskDo) => <a onClick={() => openEdit(r)}>{v}</a>
        },
        {
            title: 'EDM标题',
            dataIndex: 'edmSubject',
            width: 300,
            render: (v: string, r: EdmTaskDo) => <a onClick={() => openEdit(r)}>{v}</a>
        },
        {
            title: '发送方式',
            dataIndex: 'sendType',
            width: 100,
            valueType: 'select',
            valueEnum: [{label: 'SendCloud'}, {label: 'AWS'}]
        },
        // {
        //     title: '发件人',
        //     dataIndex: 'fromName',
        //     width: 100,
        // },
        {
            title: '发送时间',
            dataIndex: 'sendTime',
            align: 'center' as 'center',
            width: 100,
            valueType: "dateTime"
            // render: (v: any) => moment(v).format('yyyy-MM-DD HH:mm:ss')
        },
        {
            title: '发送间隔',
            dataIndex: 'in',
            align: 'center' as 'center',
            width: 60,
            render: (v: string, r: EdmTaskDo) => <>{r.intervalMin}~{r.intervalMax}</>
        },
        {
            title: '预计完成',
            dataIndex: '_time',
            align: 'center' as 'center',
            width: 100,
            render: (_: any, r: EdmTaskDo) => {
                if (r.sendTime && r.intervalMin && r.intervalMax && r.edmToList) {
                    const mailCount = JSON.parse(r.edmToList).total;
                    const processTime = (r.intervalMin + r.intervalMax) / 2 * mailCount;
                    return moment(r.sendTime).add(processTime, "seconds").format("yyyy-MM-DD HH:mm:ss")
                } else {
                    return "";
                }
            },
        },
        {
            title: '发送状态',
            dataIndex: 'sendStatus',
            align: 'center' as 'center',
            width: 80,
            render: (v: number) => DataUtil.formatSendStatus(v)
        },
        {
            title: '成功/失败/总数',
            dataIndex: 'sendNum',
            align: 'center' as 'center',
            width: 100,
            render: (v: number, r: EdmTaskDo) => <>{r.n2} / {r.n9} / {r.n}</>
        },
        {
            title: '发送日志',
            dataIndex: '_t',
            align: 'center' as 'center',
            width: 100,
            render: (v: number, r: EdmTaskDo) => (<a onClick={() => openLogs(r)}><SearchOutlined/> 查看</a>)
        },
        {
            title: '修改时间',
            dataIndex: 'updateTime',
            align: 'center' as 'center',
            width: 100,
            valueType: "dateTime"
        },
        {
            title: '操作',
            dataIndex: '_',
            width: 60,
            align: "center" as "center",
            render: (v: string, r: EdmTaskDo) => {
                return (<Space size={0} split={<Divider type="vertical"/>}>
                    <a onClick={() => openEdit(r)}><FormOutlined/></a>
                    <Popconfirm disabled={false}
                                title="确定要删除这条记录吗"
                                okText="删除"
                                cancelText={"再想想"}
                                onConfirm={() => deleteRecord(r)}
                    >
                        <a><DeleteOutlined/></a>
                    </Popconfirm>
                </Space>)
            },
        },
    ];

    const deleteRecord = async (r: EdmTaskDo) => {
        const res = await FetchUtil.awaitFetch('/mallApi/edm/delete', [r.id]);
        if (res.code === 200) {
            message.success({content: '记录已删除成功！'});
            actionRef.current?.reload();
        }
    }

    return (
        <MyProLayout>
            <ProTable
                rowKey={"id"}
                toolbar={{
                    actions: [
                        <Button type='primary' onClick={() => openEdit()} key='0'><PlusCircleOutlined/> 新建</Button>
                    ],
                }}
                actionRef={actionRef}
                columns={columns}
                cardBordered={true}
                request={async (
                    params,
                    // sort,
                    // filter,
                ) => {
                    const query: any = {pageNo: params.current, pageSize: params.pageSize, orderBy: "update_time desc"}
                    const res = await FetchUtil.awaitFetch('/mallApi/edm/list', query);
                    return {
                        success: true,
                        data: res.data.list,
                        total: res.data.total,
                    };
                }}
                search={false}/>

            <EdmEditor ref={formRef} callback={() => actionRef.current?.reload()} />

            <EdmLog ref={logsRef}
            //         openForm={openLog} taskId={logTaskId} closeCallback={() => {
            //     setOpenLog(false);
            //     setLogTaskId(0);
            // }}
            />

        </MyProLayout>
        /*
            <div id="mainTable">
                <Space size={16} style={{marginBottom: 16}}>
                    <Button key="K1"
                            type="primary"
                            onClick={() => openEdit()}>
                        <PlusCircleOutlined/> 新增
                    </Button>
                    <Button key="K2"
                            type="primary"
                            onClick={loadMainData}>
                        <RedoOutlined/> 刷新
                    </Button>
                </Space>
            </div>

            <Table key='contract'
                   rowKey='id'
                   columns={columns}
                   dataSource={mainData}
                   size="small"
                   onChange={onTableChange}
                   bordered
                   pagination={{
                       // onChange: this.onPageChange,
                       total: total,
                       pageSize: mainQuery.pageSize,
                       current: mainQuery.pageNo,
                       showTotal: (total: number, range: number[]) => `第${range[0]}-${range[1]}条 / 共${total}条`,
                       showQuickJumper: true
                   }}
            />

            <Drawer placement="right"
                    width={800}
                    open={showForm}
                    onClose={closeForm}
                    title={<Row>
                        <Col span={11}><MailOutlined/> EDM 任务</Col>
                        <Col span={11} className="right">
                            <Button type={"primary"} onClick={beforeSubmitForm}><SaveOutlined/> 保
                                存</Button>
                        </Col>
                    </Row>}>

                <EditorForm key="myForm"
                            ref={formRef}
                            inputFields={inputFields}
                            initData={editingData}
                            changeCallback={changeForm}
                            submitCallback={submitForm}
                />
                <Row><Col>
                    EDM预计发送完成时间： <span id={"EdmPlanTime"}></span>
                </Col></Row>
            </Drawer>

*/
    );
}

export default Edm;