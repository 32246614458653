import React from 'react';
import './css/App.css';
import './css/antd-override.css';

import {HashRouter} from "react-router-dom";
import {MyRouter} from "./router/router";
import {AppProvider} from "./AppContaxt";

import ProgressBarHandler from './components/ProgressBarHandler';

const App: React.FC = () => {

    // const login_info = store.get('login_info');
    // const access_token = login_info?.accessToken || "";
    // const login_power = store.get('login_power');
    //
    //
    // const initBaseInfo = async () => {
    //
    //     if (login_info && login_info.userId) {
    //         let res = await FetchUtil.awaitFetch("/adminApi/function/tree", {id: 3})
    //         if (res.code === 200) {
    //             // console.log("login_power = ", JSON.stringify(login_power));
    //             // console.log("res.data = ", JSON.stringify(res.data))
    //             if (JSON.stringify(login_power) !== JSON.stringify(res.data)) {
    //                 store.set('login_power', res.data);
    //                 // LoadPowerRouterMenu(res.data, "");
    //                 window.location.reload();
    //             }
    //         }
    //     }
    //
    //     let res = await FetchUtil.awaitFetch("/adminApi/language/list", {})
    //     if (res.code === 200) {
    //         store.set("langData", res.data.list);
    //         store.set("defaultLang", res.data?.list.find((r: any) =>
    //             r.defaultLang === 1)?.langCode || "en");
    //     }
    // }

    // initBaseInfo().then(() => console.log("initBaseInfo() => OK"));

    return (
        // @ts-ignore
        <AppProvider>
            <HashRouter>
                <ProgressBarHandler />
                <MyRouter/>
            </HashRouter>
        </AppProvider>
    );
}
export default App;

