import {Editor, Toolbar} from "@wangeditor/editor-for-react";
import React, {useEffect, useState} from "react";
import { IDomEditor, IEditorConfig, IToolbarConfig} from "@wangeditor/editor";
import FetchUtil from "../utils/FetchUtil";
import '@wangeditor/editor/dist/css/style.css'
import {Col, Row} from "antd";
import {ProFormText, ProFormTextArea} from "@ant-design/pro-components";
import {FormInstance} from "antd/lib";

// 声明类型
type InsertFnType = (url: string, alt: string, href: string) => void;
// type InsertFnVideoType = (url: string, poster: string) => void

interface IProps {
    name: string,
    label: string,
    value: string,
    height? :number,
    formRef: FormInstance<any>,
    required?: boolean,
    explain_error?: string
}

const MyWangEditor = (props: IProps) => {

    // const [isRich, setIsRich] = useState(true);
    // editor 实例
    const [editor, setEditor] = useState<IDomEditor | null>(null)
    // 编辑器内容
    const [html, setHtml] = useState<string>()

    useEffect(() => {
        console.log("useEffect ", editor, props)
        setHtml(props.value);
    }, [props.value,editor]);

    // 工具栏配置
    const toolbarConfig: Partial<IToolbarConfig> = {
        toolbarKeys: ["fontSize", "fontFamily",
            "|", "bold", "underline", "italic",
            {
                "key": "group-more-style",
                "title": "更多",
                "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M204.8 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z\"></path><path d=\"M505.6 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z\"></path><path d=\"M806.4 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z\"></path></svg>",
                "menuKeys": ["through", "code", "sup", "sub", "clearStyle"]
            },
            "color", "bgColor",
            "|", "bulletedList", "numberedList",
            {
                "key": "group-justify",
                "title": "对齐",
                "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z\"></path></svg>",
                "menuKeys": ["justifyLeft", "justifyRight", "justifyCenter", "justifyJustify", "indent", "delIndent"]
            },
            "|", "insertLink",
            {
                "key": "group-image",
                "title": "图片",
                "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M959.877 128l0.123 0.123v767.775l-0.123 0.122H64.102l-0.122-0.122V128.123l0.122-0.123h895.775zM960 64H64C28.795 64 0 92.795 0 128v768c0 35.205 28.795 64 64 64h896c35.205 0 64-28.795 64-64V128c0-35.205-28.795-64-64-64zM832 288.01c0 53.023-42.988 96.01-96.01 96.01s-96.01-42.987-96.01-96.01S682.967 192 735.99 192 832 234.988 832 288.01zM896 832H128V704l224.01-384 256 320h64l224.01-192z\"></path></svg>",
                "menuKeys": [
                    "insertImage",
                    "uploadImage"
                ]
            },
            "insertTable", "codeBlock",
            "|", "undo", "redo",
        ],
    }

    // 编辑器配置
    const editorConfig: Partial<IEditorConfig> = {
        placeholder: '请输入内容...',
        // customPaste: (editor: IDomEditor, e:ClipboardEvent) => {
        //     console.log(e)
        //     return true
        // },
        MENU_CONF: {},
    };
    // editorConfig.MENU_CONF['uploadImage'] = { // 官网是这样写的
    editorConfig.MENU_CONF!.uploadImage = { // 项目设置了eslint，所以我是这种写法
        // 自定义上传
        async customUpload(file: File, insertFn: InsertFnType) {
            // file 即选中的文件
            const formData = new FormData();
            formData.append('file', file);
            // 自己实现上传，并得到图片 url alt href
            const res = await FetchUtil.awaitFetch("/adminApi/files/upload", formData);
            if (res.code === 200) {
                console.log(res.data);
                insertFn(res.data, res.data, res.data);
            }
        }
    }

    // 及时销毁 editor ，重要！
    useEffect(() => {
        // if (editor) {
        //     const toolbar = DomEditor.getToolbar(editor)
        //     const curToolbarConfig = toolbar?.getConfig()
        //     console.log( curToolbarConfig?.toolbarKeys )
        // }

        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        }
    }, [editor])

    const onEditorChange = (val: string) => {
        if (val === "<p><br></p>") {
            val = "";
        }
        setHtml(val);
        props.formRef?.setFieldsValue({[props.name]: val});
    }
    // const onSwitchChange = (checked: boolean) => {
    //     setIsRich(checked);
    //     if (checked) {
    //
    //     }else {
    //         const t = <Input.TextArea defaultValue={html}
    //                                   // onChange={(e)=>setHtml(e.target.value)}
    //         />
    //         const container = document.getElementById('textArea1')
    //         if (container) {
    //             const root = createRoot(container)
    //             root.render(t)
    //         }
    //     }
    // }

    return (<Col span={24}>
            <Row>
                <Col className={`editorLabel ${props.required ? 'MyItemRequired':'' }`} span={4}>
                    {props.label}
                </Col>
                {/*<Col className={"editorTools"} span={20}>*/}
                    {/*<a onClick={}><CodeTwoTone /> HTML</a>*/}
                    {/*<Switch checkedChildren="富文本" unCheckedChildren="纯文本" defaultChecked*/}
                    {/*        onChange={onSwitchChange} />*/}
                {/*</Col>*/}
                {/*<Col className={"editorLabel"} span={2}></Col>*/}
                <Col span={20}>
                    <div className={"editorContainer"}>
                        <Toolbar
                            editor={editor}
                            defaultConfig={toolbarConfig}
                            mode="default"
                            style={{borderBottom: '1px solid #ddd'}}
                        />
                        <Editor
                            defaultConfig={editorConfig}
                            value={html}
                            onCreated={setEditor}
                            onChange={editor => onEditorChange(editor.getHtml())}
                            mode="default"
                            style={{height: props.height || 360, overflowY: 'hidden'}}

                        />

                        <ProFormText name={props.name}
                                         // className={"MyWangEditorHiddenText"}
                                         // hidden={true}
                                     fieldProps={{
                                         allowClear: false,style: {width:1, height: 1, border: 0}}}
                                         formItemProps={{
                                             rules: [{
                                                 required: props.required,
                                                 message: " 请输入" + props.label
                                             }]
                                         }}/>
                        {/*<div className="ant-form-item-explain-error">{editor?.getText()}</div>*/}
                    </div>
                </Col>
            </Row>
        </Col>
    )
}

export default MyWangEditor