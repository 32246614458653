import MyProLayout from "../../../components/MyProLayout";
import FetchUtil from "../../../utils/FetchUtil";
import {ActionType, ProTable} from "@ant-design/pro-components";
import React, {useRef} from "react";

const Exchange = () => {

    const actionRef = useRef<ActionType>();

    const columns:any[] = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'center' as 'center',
        },
        {
            title: 'Code',
            dataIndex: 'code',
            align: 'center' as 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            align: 'center' as 'center',
        },
        {
            title: '中间价',
            dataIndex: 'middleRate',
            align: 'right' as 'right'
        },
        // {
        //     title: '基准价',
        //     dataIndex: 'benchmark',
        //     align: 'right' as 'right'
        // },
        {
            title: '现汇买入价',
            dataIndex: 'buying',
            align: 'right' as 'right'
        },
        {
            title: '现汇卖出价',
            dataIndex: 'selling',
            align: 'right' as 'right'
        },
        {
            title: '现钞买入价',
            dataIndex: 'cashBuying',
            align: 'right' as 'right'
        },
        {
            title: '现钞卖出价',
            dataIndex: 'cashSelling',
            align: 'right' as 'right'
        },
        {
            title: '更新时间',
            dataIndex: 'updateTime',
            align: 'center' as 'center',
            valueType: 'dateTime'
        },
    ];
    return (
        <MyProLayout>
            <ProTable
                actionRef={actionRef}
                columns={columns}
                rowKey="id"
                cardBordered={true}
                // params 是需要自带的参数
                // 这个参数优先级更高，会覆盖查询表单的参数
                // params={mainQuery}
                request={async () => {
                    const res = await FetchUtil.awaitFetch('/mallApi/exchange/list', {});
                    return {
                        success: true,
                        data: res.data.list,
                    };
                }}
                search={false}
            />
        </MyProLayout>
    )
}
export default Exchange