import MyProLayout from "../../../components/MyProLayout";
import FetchUtil from "../../../utils/FetchUtil";
import {ActionType, ProTable} from "@ant-design/pro-components";
import React, {useRef} from "react";
import {Popconfirm} from "antd";

const AliSecurity = () => {

    const actionRef = useRef<ActionType>();
    const renewIp = async (r: any) => {
        const res = await FetchUtil.awaitFetch('/adminApi/aliSecurityGroup/renewIp', r);
        if (res.code === 200) {
            actionRef.current?.reload();
        }
    }

    const columns: any[] = [
        {
            title: '授权策略',
            dataIndex: 'policy',
            align: 'center' as 'center',
            width: 60,
        },
        {
            title: '优先级',
            dataIndex: 'priority',
            align: 'center' as 'center',
            width: 60,
        },
        {
            title: '协议类型',
            dataIndex: 'ipProtocol',
            align: 'center' as 'center',
            width: 60,
        },
        {
            title: '端口范围',
            dataIndex: 'portRange',
            align: 'center' as 'center',
            width: 60,
        },
        {
            title: '授权对象',
            dataIndex: 'sourceCidrIp',
            align: 'center' as 'center',
            width: 100,
        },
        {
            title: '描述',
            dataIndex: 'description',
            align: 'center' as 'center',
            width: 100,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            align: 'center' as 'center',
            width: 100,
            valueType: "dateTime"
        },
        {
            title: '操作',
            dataIndex: '_1',
            align: 'center' as 'center',
            width: 60,
            render: (v: string, r: any) => (
                <Popconfirm title="确定要将这条记录替换为当前IP吗？"
                            okText="更新"
                            onConfirm={() => renewIp(r)}
                >
                    <a>更新</a>
                </Popconfirm>

            ),
        },
    ];

    return (
        <MyProLayout>
            <ProTable
                actionRef={actionRef}
                columns={columns}
                rowKey="securityGroupRuleId"
                cardBordered={true}
                // params 是需要自带的参数
                // 这个参数优先级更高，会覆盖查询表单的参数
                // params={mainQuery}
                request={async (
                    // params,
                    // sort,
                    // filter,
                ) => {
                    const res = await FetchUtil.awaitFetch('/adminApi/aliSecurityGroup/list', {});
                    return {
                        success: true,
                        data: res.data.permissions.permission,
                    };
                }}
                search={false}
            />
        </MyProLayout>
    )
}
export default AliSecurity