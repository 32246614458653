import MyProLayout from "../../../components/MyProLayout";
import {ActionType, DragSortTable, DrawerForm} from "@ant-design/pro-components";
import FetchUtil from "../../../utils/FetchUtil";
import React, {Key, useEffect, useRef, useState} from "react";
import {Button, Divider, Form, message, Space, Tree} from "antd";
import {FormOutlined, PlusCircleOutlined, SettingOutlined} from "@ant-design/icons";
import DataUtil from "../../../utils/DataUtil";
import FormUtil from "../../../utils/FormUtil";

const Role = () => {

    const actionRef = useRef<ActionType>();
    const [formRef] = Form.useForm();
    const [openForm, setOpenForm] = useState(false);
    const [info, setInfo] = useState<any>();

    const [mainData, setMainData] = useState<any[]>([]);
    // const [roleData, setRoleData] = useState<any[]>([]);
    // const [editing, setEditing] = useState<Key[]>([]);
    const [functionData, setFunctionData] = useState<any[]>([]);
    const [functionCheckedKeys, setFunctionCheckedKeys] = useState<any[]>([]);
    const [settingRoleFunctions, setSettingRoleFunctions] = useState<any[]>([]);
    const [settingRoleFunctionsFull, setSettingRoleFunctionsFull] = useState<any[]>([]);

    let isChanged = false;

    useEffect(() => {
        loadFunction().then();
    }, [])
    const loadFunction = async () => {
        const res = await FetchUtil.awaitFetch('/adminApi/function/allTree', {});
        if (res.code === 200) {
            console.log("allTree",res.data)
            setFunctionData(res.data);
        }
    }

    const columns: any[] = [
        {
            dataIndex: '_id',
            width: 40,
            align: 'center' as 'center',
            editable: false,
            hideInSearch: true
        },
        {
            title: 'ID',
            dataIndex: 'roleId',
            width: 40,
            align: 'center' as 'center',
            editable: false,
            hideInSearch: true,
            render: (v: number) => v < 0 ? "" : v,
        },
        {
            title: '角色',
            key: 'roleName',
            dataIndex: 'roleName',
            width: 200,
            editable: true,
            editor: {type: 'text', required: true},
        },
        {
            title: '备注',
            key: 'remark',
            dataIndex: 'remark',
            width: 200,
            editable: true,
            hideInSearch: true,
            editor: {type: 'text'}
        },
        {
            title: '权限',
            dataIndex: 'roleId',
            width: 88,
            align: 'center' as 'center',
            editable: false,
            hideInSearch: true,
            render: (v: number, r: any) =>
                <a onClick={() => openEdit(r)}>
                    <SettingOutlined/>
                </a>
        }, {
            title: '修改时间',
            dataIndex: 'updateTime',
            width: 160,
            valueType: 'dateTime',
            align: 'center' as 'center',
            hideInSearch: true,
            hideInForm: true,
            editable: false
        },
        {
            title: '操作',
            dataIndex: '_opt',
            width: 200,
            align: 'center' as 'center',
            valueType: 'option',
            render: (v: any, r: any, _: any, action: any) =>
                <Space size="small" split={<Divider type="vertical"/>}>
                    <a onClick={() => {
                        action?.startEditable?.(r.roleId);
                    }}>
                        <FormOutlined/> 编辑
                    </a>
                </Space>,
        },
    ];


    const addNewRecord = () => {
        const newRecord = {
            roleId: new Date().getTime() * -1,
            sortIndex: (DataUtil.findMaxMin(mainData, "sortIndex").max.sortIndex || 0) + 1
        };
        actionRef.current?.addEditRecord?.(newRecord)
    }

    const editChange = async (k: Key[], r: any) => {
        console.log("onChange", k, r);
        // setEditing(k);
    }
    const saveRecord = async (k: any, r: any) => {
        console.log("onSave", k, r)
        if (r.roleId < 0) {
            r.roleId = 0
        }
        const res = await FetchUtil.awaitFetch('/adminApi/role/edit', [r])
        if (res.code === 200) {
            message.success({content: '保存成功！'});
            actionRef.current?.reload();
        } else {
            message.error({content: '保存失败！' + res.msg});
        }
    }

    const onDragSortEnd = async (beforeIndex: number, afterIndex: number, newDataSource: any[]) => {
        console.log(beforeIndex, afterIndex, newDataSource)
        setMainData(newDataSource);
        const records = newDataSource.map((r, i) => ({
            roleId: r.roleId,
            sortIndex: i
        }))
        const res = await FetchUtil.awaitFetch('/adminApi/role/edit', records)
        if (res.code === 200) {
            console.log(res)
            message.success({content: '排序保存成功！'});
            actionRef.current?.reload();
        }
    }

    const deleteRecord = async (k: any, r: any) => {
        console.log("onDelete", k, r)
        const res = await FetchUtil.awaitFetch('/adminApi/role/delete', [{roleId: k}])
        if (res.code === 200) {
            message.success({content: '删除成功！'});
            actionRef.current?.reload();
        } else {
            message.error({content: '删除失败！' + res.msg});
        }
    }


    const openEdit = async (r: any) => {
        const res = await FetchUtil.awaitFetch('/adminApi/role/getRoleFunction', {id: r.roleId})
        if (res.code === 200) {
            console.log("getRoleFunction",res.data)
            let keys: any[] = [];
            res.data.forEach((item: any) => {
                if (item.w && DataUtil.isEndpoint(item.functionId, functionData, "functionId"))
                    keys.push(item.functionId)
            })
            console.log("setFunctionCheckedKeys",keys)
            setFunctionCheckedKeys(keys);
            // setShowPermission(true);
            setSettingRoleFunctions([]);
            setInfo(r);
            setOpenForm(true);
        }
    }

    const closeEditor = () => {
        const doClose = (open: boolean) => {
            setOpenForm(open);
            setInfo(undefined);
        }
        FormUtil.close(isChanged, doClose);
    }
    const onFunctionCheck = (checkedKeys: any, e: any) => {
        console.log(checkedKeys, e)
        setSettingRoleFunctions([...checkedKeys, ...e.halfCheckedKeys]);
        setSettingRoleFunctionsFull([...checkedKeys]);
        setFunctionCheckedKeys([...checkedKeys])
    }
    const submitForm = async () => {
        console.log(settingRoleFunctions);
        if (!settingRoleFunctions || settingRoleFunctions.length === 0) {
            message.warning('权限设置没有任何改变！')
            return;
        }

        const powers = [];
        for (let i = 0; i < settingRoleFunctions.length; i++) {
            const fid = settingRoleFunctions[i];
            powers.push({
                roleId: info.roleId,
                functionId: settingRoleFunctions[i],
                r: 1,
                w: settingRoleFunctionsFull.indexOf(fid) >= 0 ? 1 : 0
            });
        }
        if (powers.length === 0) {
            powers.push({
                roleId: info.roleId,
                functionId: 0, r: 0, w: 0
            });
        }
        const res = await FetchUtil.awaitFetch('/adminApi/role/setRoleFunction', powers);
        if (res.code === 200) {
            message.success('权限设置成功！');
        }
        setOpenForm(false);
    }

    return (
        <MyProLayout>
            <DragSortTable
                rowKey="roleId"
                dragSortKey={"_id"}
                onDragSortEnd={onDragSortEnd}
                toolbar={{
                    actions: [
                        <Button type='primary' onClick={addNewRecord} key='0'><PlusCircleOutlined/> 新建</Button>
                    ],
                }}
                editable={{
                    onSave: saveRecord,
                    onChange: editChange,
                    onDelete: deleteRecord,
                }}
                actionRef={actionRef}
                columns={columns}
                cardBordered={true}
                dataSource={mainData}

                request={async (
                    params,
                    // sort,
                    // filter,
                ) => {
                    const query = {
                        text: params.roleName,
                        pageSize: params.pageSize,
                        pageNo: params.current
                    }
                    const res = await FetchUtil.awaitFetch('/adminApi/role/list', query);
                    setMainData(res.data.list);
                    return {success: true,};
                }}
                // search={{span: 6}}
            />
            <DrawerForm
                width={666}
                form={formRef}
                open={openForm}
                colon={false}
                labelCol={{span: 4, offset: 0}}
                title={<>权限设置 - <span className={"blue bold"}>{info?.roleName}</span></>}
                layout="horizontal"
                drawerProps={{onClose: closeEditor, forceRender: true}}
                onFinish={submitForm}
                onChange={() => isChanged = true}>
                {functionData.length > 0 &&
                <Tree key={'functionTree'}
                      showLine={true}
                      defaultExpandAll={true}
                      checkable
                      onCheck={onFunctionCheck}
                      treeData={functionData}
                      checkedKeys={functionCheckedKeys}
                      fieldNames={{ title: "functionName", key: "functionId", children: "children" }}
                />
                }
            </DrawerForm>
        </MyProLayout>
    )
}
export default Role