import {Modal} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";

const {confirm} = Modal

export default class FormUtil {

    static close(isChanged: boolean, callback: (act: boolean)=>void) {
        if (isChanged) {
            confirm({
                title: '确认关闭窗口？',
                icon: <QuestionCircleOutlined />,
                content: '检查到有未保存的修改，关闭窗口将放弃修改',
                okText: "确认关闭",
                cancelText: "取消",
                onOk() {
                    console.log('OK');
                    callback(false);
                    isChanged = false
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } else {
            callback(false);
            isChanged = false
        }
    }

}