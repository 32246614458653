import {JSONEditor, Mode} from "vanilla-jsoneditor";
import {forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import "vanilla-jsoneditor/themes/jse-theme-dark.css"
import {useAppContext} from "../AppContaxt";

const  MyJsonEditor = (props : any, ref: any) => {

    const {appState} = useAppContext();
    const refContainer: any = useRef(null);
    const refEditor: any = useRef(null);

    useImperativeHandle(ref, () => {
        return {
            // openEditor: (r: any) => setOpen(true),
            // setText: (text:any)=> refEditor.current.updateProps({content:{text: text}})
        }
    });

    useEffect(() => {
        // create editor
        // console.log("create editor", refContainer.current);
        refEditor.current = new JSONEditor({
            target: refContainer.current,
            props: {
                mode: Mode.text,
                mainMenuBar: true,
                navigationBar: true,
                statusBar: true,
                askToFormat: false
            },
        });

        return () => {
            // destroy editor
            if (refEditor.current) {
                console.log("destroy editor");
                refEditor.current.destroy();
                refEditor.current = null;
            }
        };
    }, []);

    // update props
    useEffect(() => {
        if (refEditor.current) {
            console.log("update props", props);
            refEditor.current.updateProps(props);
        }
    }, [props]);

    return (
            <div className={`vanilla-jsoneditor-react ${appState.themeState==="dark" ? "jse-theme-dark":""}`}
                 ref={refContainer}>
            </div>

        // <JSONEditor text={ '[1,2,3]'} />
    )
}
export default forwardRef(MyJsonEditor);
// export default MyJsonEditor;