// AppContext.tsx
import React, { useState, useContext } from 'react';
import store from "store";
import {RouteObject} from "react-router-dom";

// Declare type of app state
interface AppState {
    themeState: string;
    themeSelected: string;
    fontState: string;
    langData: any[];
    defaultLang: string;
    menuRouter: RouteObject[];
}
const defaultState = {
    themeState: "light",
    themeSelected:"light",
    fontState: "f14",
    langData: [],
    defaultLang: "en",
    menuRouter: [],
}

// 创建Context对象
const AppContext = React.createContext<{
    appState: AppState;
    updateAppState: (newState: Partial<AppState>) => void;
}>({
    appState: defaultState,
    updateAppState: () => {},
});

const AppProvider: React.FC = ({ children }:any) => {
    // 定义状态和更新状态的方法
    const [appState, setAppState] = useState<AppState>(
        store.get("appState") ||
        defaultState
    );

    // 定义一个更新状态的方法
    const updateAppState = (newState: Partial<AppState>) => {
        const s: AppState = {...appState, ...newState,}
        store.set("appState", s);
        setAppState(s);
    };

    return (
        // 提供状态和更新状态的方法
        <AppContext.Provider value={{appState, updateAppState}}>
            {children}
        </AppContext.Provider>
    );
}

// 自定义 hook 以方便在组件中使用 context
const useAppContext = () => useContext(AppContext);

export { AppProvider, useAppContext };
