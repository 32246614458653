import * as Icon from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/lib";
import {ProFormText, ProFormUploadButton} from "@ant-design/pro-components";
import {Button, Col, Input, message, Popconfirm, Row, Space, Tabs, UploadProps} from "antd";
import { AntDesignOutlined} from "@ant-design/icons";

// console.log(Object.keys(Icon))
export const showIcon = (icon:string, size?:number) => {
    size = size || 14;
    try {
        return icon == null || icon === '' ? ''
            : icon.indexOf('/') >= 0 ? <img src={icon} width={size} height={size} alt={""}/>
                // @ts-ignore
                : icon in Icon ? React.createElement(Icon[icon])
                    : <span>{icon}</span>
    }catch (e) {
        return <></>
    }
}

interface IProps {
    name: string,
    label: string,
    value: string,
    formRef: FormInstance<any>
}

const MyUploadIcon = (props: IProps) => {

    const [openAntd, setOpenAntd] = useState(false);
    const [imageSrc, setImageSrc] = useState("");

    useEffect(() => {
        setImageSrc(props.value);
    }, [props]);


    const onImgChange: UploadProps['onChange'] = ({fileList: newFileList}) => {
        if (newFileList.length > 0) {
            const r = newFileList[0];
            if (r.status === "done" && r.response) {
                // console.log("onChange-done", newFileList);
                if (r.response.code === 200) {
                    const imgSrc = r.response.data;
                    setImageSrc(imgSrc);
                    props.formRef?.setFieldsValue({[props.name]: imgSrc})
                } else {
                    message.error(r.response.msg).then()
                }
            }
        } else {
        }
    };

    const [iconList, setIconList] = useState<any>()
    const [iconType, setIconType] = useState('Outlined')
    const [iconKey, setIconKey] = useState('')
    // Object.keys(Icon).forEach((key) => {
    //     console.log(key)
    // })

    useEffect(() => {
        // eslint-disable-next-line array-callback-return
        const icons = Object.keys(Icon).map((key) => {
            const iconName = key.toLowerCase();
            const t = iconType?.toLowerCase() || '';
            const k = iconKey?.toLowerCase() || '';
            if (iconName.includes(t) && iconName.includes(k)) {
                // @ts-ignore
                let ico = React.createElement(Icon[key]);
                return (
                    <a style={{fontSize: 18, padding: 4}} key={key}
                       onClick={() => {
                           props.formRef.setFieldsValue({[props.name]: key});
                           setImageSrc(key)
                           setOpenAntd(false)
                       }}>
                        {ico}
                    </a>)
            }
        })
        setIconList(<div style={{width: 600, height: 360, overflow: "scroll"}}>{icons}</div>)

    }, [iconType, iconKey]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <Row>
            <Col span={4} style={{textAlign: "right", paddingRight: 16, lineHeight: "32px"}}>{props.label}</Col>
            <Col span={2} style={{lineHeight: "32px", fontSize: 24}}>{showIcon(imageSrc, 24)}</Col>
            <Col span={18}>
                <Space>
                    <Popconfirm
                        open={openAntd}
                        title={<Row>
                            <Col span={6}>Antd Icon</Col>
                            <Col span={9}><Tabs
                                onChange={(key) => setIconType(key)}
                                items={[
                                    {key: 'Outlined', label: '线框'},
                                    {key: 'Filled', label: '实底'},
                                    {key: 'TwoTone', label: '双色'},
                                ]}></Tabs></Col>
                            <Col span={9} style={{paddingTop: 8}}>
                                <Input onChange={(e: any) => setIconKey(e.target.value)}
                                       allowClear
                                       placeholder={"输入关键词搜索"}/></Col>
                        </Row>}
                        description={iconList}
                        icon={<AntDesignOutlined style={{color: '#46a9ff'}}/>}
                        showCancel={false}
                        okButtonProps={{style: {display: "none"}}}
                        onOpenChange={(e) => setOpenAntd(e)}
                    >
                        <Button style={{marginBottom: 24}} onClick={()=>setOpenAntd(true)}>Antd 图标</Button>
                    </Popconfirm>
                    <ProFormUploadButton
                        name={`${props.name}_upload`}
                        title={"上传图片"}
                        fieldProps={{
                            name: 'file',
                            itemRender: () => <></>
                        }}
                        action={"/adminApi/files/upload"}
                        accept="image/*"
                        onChange={onImgChange}
                    />
                    <ProFormText
                        name={props.name}
                        fieldProps={{
                            onChange: (v) => setImageSrc(v.target.value),
                            placeholder: "输入 Emoji or Symbols"
                        }}/>
                </Space>
            </Col>
        </Row>
    )
}
export default MyUploadIcon