import {DrawerForm, ProFormDateTimePicker, ProFormDigit, ProFormSelect, ProFormText, ProFormUploadButton
} from "@ant-design/pro-components";
import {Button, Col, Form, message, Modal, Row, Space, UploadProps} from "antd";
import React, {forwardRef, useImperativeHandle, useState} from "react";
import FormUtil from "../../../utils/FormUtil";
import FetchUtil from "../../../utils/FetchUtil";
import {EdmTaskDo} from "../../../model/EdmTaskDo";
import MyWangEditor from "../../../components/MyWangEditor";
import store from "store";
import moment from "moment/moment";
import {QuestionCircleOutlined, SaveOutlined} from "@ant-design/icons";
import DataUtil from "../../../utils/DataUtil";

const {confirm, warn} = Modal

const EdmEditor = (props: any, ref:any) => {

    const [formRef] = Form.useForm();

    const [openForm, setOpenForm] = useState(false);
    const [editingData, setEditingData] = useState<EdmTaskDo>();
    // const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [finishTime, setFinishTime] = useState<string>();

    let isChanged = false;
    const login_info = store.get("login_info");
    const access_token = login_info ? login_info.accessToken || "" : "";

    useImperativeHandle(ref, () => {
        return {
            openEditor: (r: any) => openEditor(r)
        }
    });
    const openEditor = async (r: any) => {
        formRef.resetFields();
        // setFileList([]);
        isChanged = false;
        if (r && r.id && r.id !== 0) {
            const res = await FetchUtil.awaitFetch("/mallApi/edm/detail", {id: r.id});
            if (res.code === 200) {
                r = res.data;
                setEditingData(r);
                formRef.setFieldsValue(r);
                setOpenForm(true);
                try {
                    const jes = JSON.parse(r.edmToList);
                    formRef.setFieldsValue({
                        edmToList_upload: [{
                            status: 'done',
                            uid: jes.url,
                            name: "已上传：" + jes.url + "，数量：" + jes.total,
                            url: jes.url,
                        }]
                    })
                } catch (e) {
                }
                onChangeForm();
            } else {
                message.error(res.msg);
                setEditingData(undefined);
                setOpenForm(false);
            }

        } else {
            setEditingData(undefined);
            setOpenForm(true);
        }
    }

    const closeEditor = () => {
        const doClose = (open: boolean) => {
            setOpenForm(open);
            setEditingData({} as EdmTaskDo);
        }
        FormUtil.close(isChanged, doClose);
    }

    const onFileChange: UploadProps['onChange'] = ({fileList: newFileList}) => {
        if (newFileList.length > 0) {
            const r = newFileList[0];
            if (r.status === "done" && r.response && r.response.code === 200) {
                const res = r.response.data;
                const jes = JSON.parse(res);
                message.success(res).then();
                formRef.setFieldsValue({
                    edmToList: res,
                    edmToList_upload: [{
                        status: 'done',
                        uid: jes.url,
                        name: "已上传：" + jes.url + "，数量：" + jes.total,
                        url: jes.url,
                    }]
                });
                onChangeForm();
            } else {
                // setFileList(newFileList);
            }
        } else {
            // setFileList([]);
        }
    }

    const submitForm = async (values: any) => {
        console.log(values);
        values.sendTime = new Date(values.sendTime).getTime();
        const res = await FetchUtil.awaitFetch("/mallApi/edm/edit", [values])
        if (res.code === 200) {
            message.success("保存成功！");
            setOpenForm(false);
            props.callback?.();

        } else {
            message.error(res.msg);
        }
    }

    const onChangeForm = () => {
        isChanged = true;
        const values = formRef.getFieldsValue();
        console.log(isChanged, values);
        if (values.sendTime && values.intervalMin && values.intervalMax && values.edmToList) {
            const mailCount = JSON.parse(values.edmToList).total;
            const processTime = (values.intervalMin + values.intervalMax) / 2 * mailCount
            if (processTime > 0) {
                let t = values.sendTime;
                // console.log("typeof t = ", typeof t);
                if ((typeof t) === "string") {
                    t = moment(t);
                }
                t = t.add(processTime, "seconds");
                t = t.format("YYYY-MM-DD HH:mm:ss");
                const f = t + "（邮件数：" + mailCount + "）"
                setFinishTime(f);

            } else {
                setFinishTime(undefined);
            }
        } else {
            setFinishTime(undefined);
        }
    }
    // const changeForm = (change: boolean, values: any) => {
    //     isChanged = change;
    //     console.log("changeForm", isChanged, values);
    //     if (values.sendTime && values.intervalMin && values.intervalMax && values.edmToList) {
    //         const mailCount = JSON.parse(values.edmToList).total;
    //         const processTime = (values.intervalMin + values.intervalMax) / 2 * mailCount;
    //
    //
    //         //setFinishTime(moment(values.sendTime).add(processTime,"seconds").format("yyyy-MM-DD HH:mm:ss"))
    //         // ReactDOM.render(<span className="blue strong">
    //         //         {moment(values.sendTime).add(processTime, "seconds").format("yyyy-MM-DD HH:mm:ss")}
    //         //         （邮件数：{mailCount}）
    //         //     </span>,
    //         //     document.getElementById("EdmPlanTime"))
    //     }
    // }

    const btnPause = async () => {
        if (editingData) {
            confirm({
                title: '确认【暂停】当前任务？',
                icon: <QuestionCircleOutlined />,
                content: '暂停后可以修改任务，并重新开始或重新发送',
                okText: "确认暂停",
                cancelText: "取消",
                async onOk() {
                    const res = await FetchUtil.awaitFetch("/mallApi/edm/pause", [editingData.id])
                    if (res.code === 200) {
                        message.success("任务已暂停！");
                        setOpenForm(false);
                        props.callback?.();

                    } else {
                        message.error(res.msg);
                    }
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
    }
    const btnContinue = async () => {
        if (editingData) {
            confirm({
                title: '确认【继续】当前任务？',
                icon: <QuestionCircleOutlined />,
                content: '继续发送将按当前发送列表继续发送，如果您修改了Email列表，建议执行【重新开始】',
                okText: "确认继续",
                cancelText: "取消",
                async onOk() {
                    const res = await FetchUtil.awaitFetch("/mallApi/edm/continue", [editingData.id])
                    if (res.code === 200) {
                        message.success("任务已继续开始发送！");
                        setOpenForm(false);
                        props.callback?.();

                    } else {
                        message.error(res.msg);
                    }

                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
    }
    const btnReset = async () => {
        if (editingData) {
            confirm({
                title: '确认【重新开始】当前任务？',
                icon: <QuestionCircleOutlined />,
                content: '【重新开始】重新创建发送列表，并全部重新发送！',
                okText: "确认重新开始",
                cancelText: "取消",
                async onOk() {
                    const res = await FetchUtil.awaitFetch("/mallApi/edm/reset", [editingData.id])
                    if (res.code === 200) {
                        message.success("任务已设置为重新发送！");
                        setOpenForm(false);
                        props.callback?.();

                    } else {
                        message.error(res.msg);
                    }
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
    }

    const required: any = {formItemProps: {rules: [{required: true}]}};
    const requiredEmail: any = {formItemProps: {rules: [{required: true, type: "email"}]}};
    const colProps12: any = {colProps: {span: 12}, labelCol: {span: 8}};

    return (
        <DrawerForm
            width={980}
            form={formRef}
            open={openForm}
            colon={false}
            labelCol={{span: 4, offset: 0}}
            title={"EDM 任务"}
            layout="horizontal"
            grid={true}
            drawerProps={{onClose: closeEditor, forceRender: true}}
            onFinish={submitForm}
            onChange={onChangeForm}
            submitter={{
                submitButtonProps: {icon: <SaveOutlined/>, disabled: editingData?.sendStatus === 1},
                render: (props, defaultDoms) => {
                    return [
                        <span className={"bold blue"} key={0}>
                            当前状态：{DataUtil.formatSendStatus(editingData?.sendStatus)}
                        </span>,
                        <div style={{width: 80}} key={1}></div>,
                        <Space size={16} key={"ex"}>
                            {(!editingData?.sendStatus || editingData?.sendStatus === 0) &&   // 未发送
                                <>{defaultDoms}</>
                            }
                            {editingData?.sendStatus === 1 &&   // 发送中
                                <>
                                    <Button key={"pause"} danger onClick={btnPause}>暂停</Button>
                                    <Button key={"cancel"} disabled>取消</Button>
                                    <Button key={"submit"} icon={<SaveOutlined/>} disabled>确定</Button>
                                </>
                            }
                            {editingData?.sendStatus === 2 &&   // 完成
                                <>
                                    <Button key={"reset"} danger onClick={btnReset}>重新开始</Button>
                                    {defaultDoms}
                                </>
                            }
                            {editingData?.sendStatus === 3 &&   // 暂停
                                <>
                                    <Button key={"goon"} danger onClick={btnContinue}>继续</Button>
                                    <Button key={"reset"} danger onClick={btnReset}>重新开始</Button>
                                    {defaultDoms}
                                </>
                            }
                            {editingData?.sendStatus === 9 &&   // 失败
                                <>
                                    <Button key={"reset"} danger onClick={btnReset}>重新开始</Button>
                                    {defaultDoms}
                                </>
                            }
                            <div></div>
                        </Space>,
                        // ...defaultDoms,
                    ];
                },
            }}
        >
            <ProFormText name={"taskTitle"}
                         label={"EDM任务标题"}
                         {...colProps12}
                         {...required}/>
            <ProFormSelect name={"sendType"}
                           label={"发送方式"}
                           fieldProps={{defaultValue: 'SendCloud'}}
                           initialValue={"SendCloud"}
                           options={[
                               {label: 'SendCloud', value:'SendCloud'},
                               {label: 'AWS', value:'AWS'},
                           ]}
                           {...colProps12}
                           {...required}/>
            <ProFormText name={"fromName"}
                         label={"发件人名称"}
                         {...colProps12}
                         {...requiredEmail} />
            <ProFormText name={"replyEmail"}
                         label={"回复地址"}
                         {...colProps12}
                         {...requiredEmail}/>
            <ProFormText name={"edmSubject"}
                         label={"EDM标题"}
                         {...required} />
            <MyWangEditor name={"edmContent"}
                          label={"EDM内容"}
                          value={editingData?.edmContent || ""}
                          formRef={formRef}
            />
            <ProFormUploadButton name={"edmToList_upload"}
                                 label={"EDM发送用户"}
                                 fieldProps={{
                                     name: 'file',
                                     headers: {"accessToken": access_token},
                                 }}
                                 // tooltip={""}
                                 accept=".xls,.xlsx"
                                 action={"/mallApi/edm/uploadToList"}
                                 max={1}
                                 onChange={onFileChange} extra={<a href="/import/邮件模版.xlsx">下载邮件模版</a>}/>


            <ProFormText name={"edmLabel"}
                         label={"标签"}
                         {...required}
                         {...colProps12} />
            <ProFormDateTimePicker name={"sendTime"}
                                   label={"开始发送时间"}
                                   formItemProps={{rules: [{required: true}]}}
                                   fieldProps={{onChange: onChangeForm}}
                                   {...colProps12} />
            <ProFormDigit name={"intervalMin"}
                          label={"发送间隔（秒）"}
                          initialValue={10}
                          {...required} {...colProps12}
                          fieldProps={{precision: 0}}/>
            <ProFormDigit name={"intervalMax"}
                          label={"至"}
                          initialValue={20}
                          {...required} {...colProps12}
                          fieldProps={{precision: 0}}/>

            <ProFormText hidden name={"edmToList"}/>
            <ProFormText hidden name={"id"}/>

            <Row>
                <Col style={{paddingLeft: 32}}>EDM预计完成时间：
                    <span className="blue bold">{finishTime}</span>
                </Col>
            </Row>

        </DrawerForm>
    )
}

// let inputFields: any[] = [
//     {type: "hidden", name: "id", label: "id"},
//     {type: "input", name: , label: "", required: true},
//     {type: "input", name: "fromName", label: , required: true, width: 12},
//     {type: "input", name: "replyEmail", label: "回复地址", required: true, width: 12},
//     {type: "input", name: "edmSubject", label: "EDM标题", required: true},
//     {type: "rich", name: "edmContent", label: "EDM内容", required: true},
//     {type: "file", name: "edmToList", label: "EDM发送用户", required: true},
//     {type: "input", name: "edmLabel", label: "标签", required: true, width: 12},
//     {type: "datetime", name: "sendTime", label: "EDM发送时间", required: true, width: 12},
//     {type: "number", name: "intervalMin", label: "发送间隔（秒）", required: true, width: 12},
//     {type: "number", name: "intervalMax", label: "至", required: true, width: 12},
//     // { label: "EDM预计发送完成时间"},
// ];

export default forwardRef(EdmEditor)