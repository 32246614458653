import MyProLayout from "../../../components/MyProLayout";
import FetchUtil from "../../../utils/FetchUtil";
import {ActionType, DragSortTable} from "@ant-design/pro-components";
import React, {useRef, useState} from "react";
import {Button, message, Tag} from "antd";
import {PaymentChannelDo} from "../../../model/PaymentChannelDo";
import {PlusCircleOutlined} from "@ant-design/icons";
import PayEditor from "./payEditor";

const PayChannel = () => {

    const actionRef = useRef<ActionType>();
    const editorRef = useRef();
    const [mainData, setMainData] = useState<PaymentChannelDo[]>([]);


    const onDragSortEnd = async (beforeIndex: number, afterIndex: number, newDataSource: any[]) => {
        console.log(beforeIndex, afterIndex, newDataSource)
        setMainData(newDataSource);
        const records = newDataSource.map((r, i) => ({
            id: r.id,
            sortIndex: i
        }))
        const res = await FetchUtil.awaitFetch('/mallApi/payChannel/edit', records)
        if (res.code === 200) {
            console.log(res)
            message.success({content: '排序保存成功！'});
            actionRef.current?.reload();
        }
    }
    const openEdit = (r?: PaymentChannelDo) => {
        console.log(r);
        if (!r) {
            r = {} as PaymentChannelDo;
        }
        (editorRef.current as any).openEditor(r);
        // setShowEdit(true);
    }

    const columns: any[] = [
        {dataIndex: '_id', width: 40, align: 'center' as 'center', hideInSearch: true, hideInForm: true},
        {
            title: "ID",
            dataIndex: "id",
            width: 40,
            align: 'center' as 'center'
        },
        {
            title: "LOGO",
            dataIndex: "image",
            width: 100,
            align: 'center' as 'center',
            render: (v: string, r: PaymentChannelDo) =>
                <a onClick={() => openEdit(r)}><img src={v} alt="" style={{maxHeight: 36}}/></a>
        },
        {
            title: "名称",
            dataIndex: "payName",
            width: 120,
            render: (v: string, r: PaymentChannelDo) => <a onClick={() => openEdit(r)}>{v}</a>
        },
        {
            title: "code",
            dataIndex: "payCode",
            width: 120,
        },
        {title: "费率%", dataIndex: "feeRate", width: 100, align: 'center' as 'center'},
        {title: "固定费用", dataIndex: "feeFixed", width: 100, align: 'center' as 'center'},
        {
            title: "状态", dataIndex: "payStatus", width: 100, align: 'center' as 'center',
            render: (v: number) => v === 1 ? <Tag color="green">启用</Tag> : <Tag color="red">停用</Tag>
        },
        {
            title: "推荐", dataIndex: "topping", width: 100, align: 'center' as 'center',
            render: (v: number) => v === 1 ? <Tag color="blue">推荐</Tag> : ""
        },
        {
            title: '修改时间',
            dataIndex: 'updateTime',
            width: 200,
            valueType: 'dateTime',
            align: 'center' as 'center'
        }
    ]

    return (
        <MyProLayout>
            <DragSortTable
                rowKey={"id"}
                dragSortKey={"_id"}
                onDragSortEnd={onDragSortEnd}
                toolbar={{actions: [
                        <Button type='primary' onClick={()=>openEdit()} key='0'><PlusCircleOutlined/> 新建</Button>
                    ],
                }}
                actionRef={actionRef}
                columns={columns}
                cardBordered={true}
                // params={parents}
                dataSource={mainData}

                request={async () => {
                    const res = await FetchUtil.awaitFetch('/mallApi/payChannel/list', {});
                    console.log("payChannel",res)
                    setMainData(res.data.list)
                    return {
                        success: true
                    };
                }}
                search={false}
            />
            <PayEditor ref={editorRef}
                       callback={()=>actionRef.current?.reload()} />
        </MyProLayout>
    )
}
export default PayChannel