// ProgressBarHandler.tsx
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

const ProgressBarHandler: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        const handleStart = () => NProgress.start();
        const handleStop = () => NProgress.done();

        handleStart();
        handleStop();

        return () => {
            NProgress.done();
        };
    }, [location]);

    return null;
};

export default ProgressBarHandler;
