
import {ParamSimpleDo} from "../model/ParamSimpleDo";
import {CategoryDo} from "../model/CategoryDo";
import FetchUtil from "../utils/FetchUtil";
import {ParamDo} from "../model/ParamDo";
import {CategorySimpleDo} from "../model/CategorySimpleDo";

/**
 * 参数及分类的简易读取方法类
 */
export default class SimpleList {

    /**
     * 参数的简易读取方法
     * @param parentId Root
     */
    static param = async (parentId: number | string) => {
        const res = await FetchUtil.awaitFetch('/adminApi/param/list', {parentId: parentId});
        if (res.code) {
            const data: ParamSimpleDo[] = res.data.list.map((r: ParamDo): ParamSimpleDo => {
                return {
                    id: r.paramId,
                    value: r.paramId?.toString(),
                    label: r.paramName,
                    text: r.paramName,
                    code: r.paramCode,
                    icon: r.icon,
                    paramValue:r.paramValue,
                    mappingCode: r.mappingCode,
                }
            });
            return data;
        }
        return []
    }

    /**
     * 商品分类的简易读取方法
     * @param parentId Root
     */
    static category = async (parentId: number) => {
        const res = await FetchUtil.awaitFetch('/mallApi/category/list', {parentId: parentId});
        if (res.code) {
            const data: CategorySimpleDo[] = res.data.list.map((r: CategoryDo) => {
                return {
                    id: r.id,
                    value: r.id,
                    label: r.categoryName,
                    text: r.categoryName,
                    code: r.categoryCode,
                    icon: r.icon,
                    mappingCode: r.mappingCode
                }
            });
            return data;
        }
        return []
    }

    /**
     * 商品分类（树状）简易读取方法
     * @param parentId root
     */
    static categoryTree = async (parentId: number | string) => {

        const getSimpleTree = (date: any[], idField: string, nameField: string, codeField: string): any[] => {
            return date?.map((r: any) => {
                return {
                    id: r[idField],
                    key: r[idField],
                    value: r[idField],
                    label: r[nameField],
                    text: r[nameField],
                    code: r[codeField],
                    children: getSimpleTree(r.children, idField, nameField, codeField)
                }
            });
        }

        const res = await FetchUtil.awaitFetch('/mallApi/category/listTree', {id: parentId});
        if (res.code === 200) {
            return getSimpleTree(res.data, 'id', 'categoryName', 'categoryCode');
        } else {
            return []
        }
    }
}