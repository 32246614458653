import MyProLayout from "../components/MyProLayout";
import {Empty} from "antd";

const Dashboard = () => {

    return (
        <MyProLayout>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                   description={<span style={{fontFamily: "Bahnschrift, Courier New"}}>
                       <span style={{color: "#888",fontSize:16}}>
                       Welcome |</span> EasyGameNow </span>}/>
            {/*<Skeleton  />*/}
        </MyProLayout>
    )
}

export default Dashboard