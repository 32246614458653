import {Key, useEffect, useRef, useState} from "react";
import {Button, Col, Flex, message, Row, Tree} from "antd";
import React from "react";
import {SisternodeOutlined,CaretUpOutlined,CaretDownOutlined,RedoOutlined, FormOutlined,
    SortAscendingOutlined, PlusCircleOutlined
} from "@ant-design/icons";
import {FunctionDo} from "../../../model/FunctionDo";
import MyProLayout from "../../../components/MyProLayout";
import FetchUtil from "../../../utils/FetchUtil";
import { ProCard} from "@ant-design/pro-components";
import DataUtil from "../../../utils/DataUtil";
import FunctionEditor from "./FunctionEditor";
import {useParams} from "react-router-dom";
import {showIcon} from "../../../components/MyUploadIcon";


const Function = (props: any) => {

    const editorRef = useRef();
    // const actionRef = useRef<ActionType>();
    let {rootId} = useParams();
    rootId = rootId || "3"

    // const [rootId, setRootId] = useState(0);
    const [mainData, setMainData] = useState<any[]>([]);
    // const [sorting, setSorting] = useState(false);
    // const [editing, setEditing] = useState<Key[]>([])
    const [openKeys, setOpenKeys] = useState<Key[]>([])
    const [allKeys, setAllKeys] = useState<Key[]>([])

    useEffect(() => {
        // setRootId(props.match?.params.rootId || 3);
        // console.log(Icon)
    }, [props])

    useEffect(() => {
        loadMainData().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rootId])

    const loadMainData = async () => {
        if (!rootId || rootId === "") {
            return;
        }
        let keys: Key[] = []
        const cleanData = (data: FunctionDo[]) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].children && data[i].children!.length > 0) {
                    data[i].children = cleanData(data[i].children!);
                    keys = [...keys, data[i].functionId];
                }
                // data[i].title = data[i].functionName;
                data[i].key = data[i].functionId;
                // data[i].icon= <MenuOutlined className='grey' />;
            }
            return data;
        }
        const res = await FetchUtil.awaitFetch("/adminApi/function/allTree", {id: rootId})
        if (res.code === 200) {
            let myData = cleanData(res.data);
            setMainData(myData);
            setOpenKeys(keys);
            setAllKeys(keys)
            // console.log(openKey)
        }
    }

    // const onDragEnter = (info: any) => {
    //     console.log('onDragEnter', info);
    //     // expandedKeys 需要受控时设置
    //     // this.setState({
    //     //   expandedKeys: baseInfo.expandedKeys,
    //     // });
    // };

    const onDrop = (info: any) => {
        // console.log('onDrop', info);
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
        /**
         * 通过计算获取到移入的位置 dropPosition为计算后的德到的实际位置
         * 0  拖入到当前节点
         * -1 拖入到当前节点前
         * 1  拖入到当前节点后
         */

        const loop = (data: any, key: any, callback: any) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children, key, callback);
                }
            }
        };
        const data = [...mainData];

        // Find dragObject
        let dragObj: any;
        loop(data, dragKey, (item: any, index: any, arr: any[]) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        if (!info.dropToGap) {
            // Drop on the content
            loop(data, dropKey, (item: { children: any[]; }) => {
                item.children = item.children || [];
                // where to insert 示例添加到头部，可以是随意位置
                item.children.unshift(dragObj);
            });
        } else if (
            (info.node.props.children || []).length > 0 && // Has children
            info.node.props.expanded && // Is expanded
            dropPosition === 1 // On the bottom gap
        ) {
            loop(data, dropKey, (item: { children: any[]; }) => {
                item.children = item.children || [];
                // where to insert 示例添加到头部，可以是随意位置
                item.children.unshift(dragObj);
                // in previous version, we use item.children.push(dragObj) to insert the
                // item to the tail of the children
            });
        } else {
            let ar: any[] = [];
            let i = 0;
            loop(data, dropKey, (item: any, index: any, arr: any) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
            }
            console.log(ar)
        }

        setMainData(data);
        saveSort(data)
    };

    const saveSort = async (data: any[]) => {
        // console.log('saveSort', mainData);
        let saveData: any[] = [];
        let sidx = 1;
        const getSortedData = (d: any[], p: string) => {
            d.forEach((v: any) => {
                saveData.push({functionId:v.functionId, sortIndex: sidx, parentId: p});
                sidx++;
                if (v.children && v.children.length > 0) {
                    getSortedData(v.children, v.functionId);
                }
            })
        }
        getSortedData(data, rootId || "3");
        console.log('saveSorted', saveData);

        const res = await FetchUtil.awaitFetch("/adminApi/function/edit", saveData);
        if (res.code===200) {
            message.success({content: '排序保存成功！'});
            loadMainData().then();
        }
    }
    // const deleteRecord = async (k: any, r: any) => {
    //
    //     const res = await FetchUtil.awaitFetch("/adminApi/function/delete", [{functionId: k}])
    //     if (res.code == 200) {
    //         message.success({content: '记录已删除成功！'});
    //         loadMainData().then();
    //     }
    // }

    const openEdit = (r: FunctionDo) => {
        (editorRef.current as any).openEditor(r);
    }
    const openNewChild = (r?: FunctionDo) => {
        let parentNode;
        if (r) {
            const sort = DataUtil.findMaxMin( r.children, "sortIndex").max.sortIndex + 1;
            parentNode = {functionId: 0, parentId: r.functionId,sortIndex:sort}
        }else{
            const sort = DataUtil.findMaxMin( mainData, "sortIndex").max.sortIndex + 1;
            parentNode = {functionId: 0, parentId: rootId,sortIndex:sort}
        }
        (editorRef.current as any).openEditor(parentNode);
    }

    const nodeRender = (node: any) => {
        // console.log(node)
        return (
            <Row className={"treeTableNode"} wrap={false}>
                <Col flex={"auto"} >
                    {showIcon(node.icon ,12)}&nbsp; &nbsp;
                    <a onClick={() => openEdit(node)}>{node.functionNameOrig}
                    </a>
                </Col>
                <Col flex={"0 0 5vw"} className={"center"}>{node.functionId}</Col>
                <Col flex={"0 0 5vw"} className={"center"}>
                    {showIcon(node.icon ,12)}
                </Col>
                <Col flex={"0 0 13vw"}>{node.pageUrl}</Col>
                <Col flex={"0 0 10vw"}>{node.functionInterface}</Col>
                <Col flex={"0 0 10vw"} className={"center"}>
                    <span><a onClick={() => openNewChild(node)}><SisternodeOutlined/> 添加</a></span>
                </Col>
                <Col flex={"0 0 10vw"} className={"center"}>
                    <a onClick={() => openEdit(node)}><FormOutlined/> 编辑</a></Col>
            </Row>
        )
    }

    return (
        <MyProLayout>
            <ProCard>

                <Flex className={"treeTableToolbar"} justify={"flex-end"} align={"center"} gap={8}>
                    <Button type="primary" onClick={()=>openNewChild()}><PlusCircleOutlined/> 新建</Button>
                    <Button type="text" onClick={() => loadMainData()}><RedoOutlined/></Button>
                    <Button type="text" onClick={() => setOpenKeys(allKeys)}><CaretDownOutlined/></Button>
                    <Button type="text" onClick={() => setOpenKeys([])}><CaretUpOutlined/></Button>
                </Flex>
                <Row className={"treeTableHead"}>
                    <Col flex={"32px"}><SortAscendingOutlined /></Col>
                    <Col flex={"auto"}><span>菜单</span></Col>
                    <Col flex={"0 0 5vw"} className={"center"}><span>ID</span></Col>
                    <Col flex={"0 0 5vw"} className={"center"}><span>图标</span></Col>
                    <Col flex={"0 0 13vw"}><span>路径</span></Col>
                    <Col flex={"0 0 10vw"}><span>Interface</span></Col>
                    <Col flex={"0 0 10vw"} className={"center"}><span>子集</span></Col>
                    <Col flex={"0 0 10vw"} className={"center"}>操作</Col>
                </Row>
                <Tree
                    key={"mTree"}
                    className="MyDraggableTreeTable"
                    defaultExpandAll={true}
                    expandedKeys={openKeys}
                    onExpand={(e) => setOpenKeys(e)}
                    titleRender={nodeRender}
                    draggable
                    blockNode
                    // showIcon
                    // showLine={true}
                    // onDragEnter={onDragEnter}
                    onDrop={onDrop}
                    treeData={mainData}
                />
            </ProCard>
            <FunctionEditor ref={editorRef} callback={loadMainData}/>
        </MyProLayout>
    );
}
export default Function;