import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import AxiosUtil from "../../../utils/AxiosUtil";
import {Drawer} from "antd";
import {ExpandOutlined} from "@ant-design/icons";
import MyRawData from "../../../components/MyRawData";
import {ActionType, ProTable} from "@ant-design/pro-components";
import FetchUtil from "../../../utils/FetchUtil";
import DataUtil from "../../../utils/DataUtil";

const EdmLog = (props: any, ref:any) => {

    // const formRef = useRef();
    const actionRef = useRef<ActionType>();

    // const [total, setTotal] = useState(0);
    // const [mainData, setMainData] = useState<any[]>();
    const [mainQuery, setMainQuery] = useState<any>({id: 0, orderBy: "id"} as any);

    const [openForm, setOpenForm] = useState(false);
    const [openForm2, setOpenForm2] = useState(false);
    const [form2Text, setForm2Text] = useState<string>("");

    useEffect(() => {
        actionRef.current?.reload();
    }, [mainQuery]);

    useImperativeHandle(ref, () => {
        return {
            openEditor: (r: any) => {
                setMainQuery({id: r.id});
                setOpenForm(true);
            }
        }
    });
    // useEffect(() => {
    //     setMainQuery({id: props.taskId});
    //     setOpenForm(props.openForm);
    //     actionRef.current?.reload();
    // }, [props]);

    // const loadMainData = () => {
    //     AxiosUtil.AxiosRequest('/mallApi/edm/logList', mainQuery,
    //         (suc, dat) => {
    //             if (suc) {
    //                 setMainData(dat.list)
    //                 setTotal(dat.total)
    //             }
    //         });
    //     console.log("loadMainData - ok")
    // }
    // const onTableChange = (pagination: any, filters: any, sorter: any, extra: {
    //     currentDataSource: any[],
    //     action: "paginate" | "sort" | "filter"
    // }) => {
    //
    //     let querys = {...mainQuery};
    //     switch (extra.action) {
    //         case "paginate":
    //             querys.pageNo = querys.pageSize == pagination.pageSize ? pagination.current : 1;
    //             querys.pageSize = pagination.pageSize
    //             break;
    //     }
    //     setMainQuery(querys)
    // }

    const columns: any[] = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: '发送地址',
            dataIndex: 'edmTo',

        },
        {
            title: '发送时间',
            dataIndex: 'sendTime',
            align: 'center' as 'center',
            valueType: "dateTime"
            // render: (v: number) => {
            //     return v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm:ss')
            // },
        },
        {
            title: '发送状态',
            dataIndex: 'sendStatus',
            align: 'center' as 'center',
            render: (v: number) => DataUtil.formatSendStatus(v)
        },
        {
            title: 'Result',
            dataIndex: '_remark',
            align: 'center' as 'center',
            render: (v: string, r: any) => <a onClick={() => onOpenForm2(r.id)}><ExpandOutlined/> 元数据</a>
        },
    ];

    const onOpenForm2 = (id: number) => {
        AxiosUtil.AxiosRequest("/mallApi/edm/logDetail", {id: id},
            (suc, data) => {
                if (suc) {
                    setForm2Text(data.remark);
                    setOpenForm2(true);
                }
            })
    }
    const closeForm2 = () => {
        setOpenForm2(false);
    }
    return (

        <Drawer placement="right"
                width={800}
                open={openForm}
                onClose={() => {
                    setOpenForm(false);
                }}
                title={"EDM 日志"}
                forceRender={true}>

            <ProTable key='contract'
                      actionRef={actionRef}
                      rowKey='id'
                      columns={columns}
                      size="small"
                      search={false}
                      toolbar={{
                          search: {
                              onSearch: (value: string) => {
                                  setMainQuery({...mainQuery, text: value});
                              },
                          }
                      }}
                      request={async (
                          params,
                          // sort,
                          // filter,
                      ) => {
                          if (mainQuery.id === 0) {
                              return {success: true, data: []}
                          }
                          const query: any = {...mainQuery, pageSize: params.pageSize, pageNo: params.current}
                          const res = await FetchUtil.awaitFetch('/mallApi/edm/logList', query);
                          // setMainData(res.data.list);
                          return {
                              success: true,
                              data: res.data.list,
                              total: res.data.total,
                          };
                      }}
            />

            <Drawer placement="right"
                    width={680}
                    open={openForm2}
                    onClose={closeForm2}
                    title={<span><ExpandOutlined/> 元数据</span>}>
                <MyRawData rawData={form2Text} label="Post Raw"/>
            </Drawer>
        </Drawer>
    );
}
export default forwardRef(EdmLog)