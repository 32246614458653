import MyProLayout from "../../../components/MyProLayout";
import React, {Key, useEffect, useRef, useState} from "react";
import {ActionType, DragSortTable} from "@ant-design/pro-components";
import {Button, Divider, message, Space} from "antd";
import FetchUtil from "../../../utils/FetchUtil";
import {
    ArrowLeftOutlined,
    FormOutlined,
    GlobalOutlined,
    PlusCircleOutlined,
} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {ListDo} from "../../../model/listDo";
import MultiLanguageEditor from "../../../components/MultiLanguageEditor";
import DataUtil from "../../../utils/DataUtil";
import {ParamDo} from "../../../model/ParamDo";

const Params = (props: any) => {

    const mlRef = useRef();
    const actionRef = useRef<ActionType>();
    let {rootId} = useParams<{ rootId: string }>();
    rootId = rootId || "1";

    const [parents, setParents] = useState<ListDo[]>(
        [{value: rootId, text: ''}])
    const [mainData, setMainData] = useState<ParamDo[]>([]);
    const [treeData, setTreeData] = useState<ParamDo[]>([]);
    const [editing, setEditing] = useState<Key[]>([])

    // let rand : number;
    useEffect(() => {
        loadTreeData().then();
    }, [props]);

    const loadTreeData = async () => {
        const res2 = await FetchUtil.awaitFetch("/adminApi/param/listTree", {id: 1});
        if (res2.code === 200) {
            setTreeData(res2.data)
        }
    }

    const openMl = (r: any) => {
        (mlRef.current as any).openEditor({type: "param", id: r.paramId});
    }

    const columns: any[] = [
        {
            dataIndex: '_id',
            width: 40,
            align: 'center' as 'center',
            editable: false
        },
        {
            title: 'ID',
            dataIndex: 'paramId',
            width: 60,
            align: 'center' as 'center',
            editable: false,
            render: (v:number) => v<0 ? "" : v,
        },
        {
            title: 'Level',
            dataIndex: 'parentId',
            width: 200,
            valueType: 'cascader',
            // render: () => parents[parents.length - 1].text,
            fieldProps: {
                multiple: false,
                options: treeData,
                fieldNames: {label: "paramName", value: "paramId"},
                changeOnSelect: true,
                expandTrigger: 'hover',
                // treeDefaultExpandAll: true,
                // treeDefaultExpandedKeys: parents.map((r)=>r.id),
                // treeLine: true,
                // popupMatchSelectWidth: false,
                // onload:()=>{}
            }
        },
        {
            title: 'Name',
            dataIndex: 'paramName',
            width: 200,
            // editor: {type: 'text', required: true},
            // 对应字段是paramName，但是视图中paramName会返回默认语言的，或指定语言，原始内容放在paramNameOrig字段
            render: (v: any, r: any) => <a onClick={() => loadChildren(r)}>{r.paramNameOrig}</a>
        },
        {
            title: 'Code',
            dataIndex: 'paramCode',
            width: 200,
        },
        {
            title: 'Value',
            dataIndex: 'paramValue',
            width: 200,
            valueType: "number"
        },
        {
            title: 'Icon',
            dataIndex: 'icon',
            width: 200,
        },
        {
            title: 'Mapping',
            dataIndex: 'mappingCode',
            width: 200,
        },
        {
            title: "多语言",
            dataIndex: "_ml",
            align: 'center' as 'center',
            width: 100,
            render: (v: any, r: any) => <a onClick={() => openMl(r)} hidden={r.paramId === 0}><GlobalOutlined/> 设置</a>,
            editable: false
        },
        {
            title: '操作',
            dataIndex: '_opt',
            width: 200,
            align: 'center' as 'center',
            valueType: 'option',
            render: (v: any, r: any, _: any, action: any) =>
                <Space size="small" split={<Divider type="vertical"/>}>
                    <Button type={"link"} onClick={() => {action?.startEditable?.(r.paramId);}}>
                        <FormOutlined/> 编辑
                    </Button>
                </Space>,
        },
    ];

    const editChange = async (k:Key[], r:any) => {
        console.log("onChange", k, r);
        setEditing(k);
    }
    const cancelEditable = () => {
        if (editing.length>0) {
            actionRef.current?.cancelEditable?.(editing[0])
        }
    }
    const loadChildren = (item: ParamDo) => {
        if (parents.length === 0 ||parents[parents.length-1].value !== item.paramId ) {
            cancelEditable();
            const p = parents;
            p.push({'value': item.paramId, 'text': item.paramName});
            setParents(p);
            // loadMainData().then();
            actionRef.current?.reload();
        }
    }
    const loadParent = () => {
        if (parents.length > 1) {
            cancelEditable();
            const p = parents;
            p.pop();
            setParents(p);
            // loadMainData().then();
            actionRef.current?.reload();
        }
    }

    const addNewRecord = () => {
        const newRecord = {
            paramId: new Date().getTime() * -1,
            // parentId: parents[parents.length - 1].value,
            parentId: parents.map(p=> p.value).slice(1),
            sortIndex:(DataUtil.findMaxMin(mainData, "sortIndex").max.sortIndex || 0) + 1
        };
        actionRef.current?.addEditRecord?.(newRecord);

        let anchorElement = document.getElementById("endOfTable");
        anchorElement?.scrollIntoView({behavior:"smooth"});
    }

    const saveRecord = async (k: any, r: any) => {
        console.log("onSave", k, r)
        if (!r.parentId){
            r.parentId = parents[parents.length - 1].value;
        } else if (Array.isArray(r.parentId) && r.parentId.length === 0) {
            r.parentId = parents[parents.length - 1].value;
        } else if (Array.isArray(r.parentId) && r.parentId.length > 0) {
            r.parentId = r.parentId[r.parentId.length - 1];
        }
        if (r.paramId <= 0) {
            r.paramId = undefined
        }
        console.log(r)
        const res = await FetchUtil.awaitFetch('/adminApi/param/edit', [r])
        if (res.code === 200) {
            message.success({content: '保存成功！'});
            actionRef.current?.reload();
            loadTreeData().then();
        } else {
            message.error({content: '保存失败！' + res.msg});
        }
    }

    const deleteRecord = async (k: any, r: any) => {
        console.log("onDelete", k, r)
        const res = await FetchUtil.awaitFetch('/adminApi/param/delete', [k])
        if (res.code===200) {
            message.success({content: '删除成功！'});
            actionRef.current?.reload();
            loadTreeData().then();
        }else {
            message.error({content: '保存失败！' + res.msg});
        }
    }

    const onDragSortEnd = async (beforeIndex: number, afterIndex: number, newDataSource: any[]) => {
        console.log(beforeIndex, afterIndex, newDataSource)
        setMainData(newDataSource);
        const records = newDataSource.map((r, i) => ({
            paramId: r.paramId,
            sortIndex: i
        }))
        const res = await FetchUtil.awaitFetch('/adminApi/param/edit', records)
        if (res.code === 200) {
            console.log(res)
            message.success({content: '排序保存成功！'});
            actionRef.current?.reload();
        }
    }

    return (
        <MyProLayout>
            <DragSortTable
                rowKey={"paramId"}
                dragSortKey={"_id"}
                onDragSortEnd={onDragSortEnd}
                pagination={{pageSize:10000}}
                toolbar={{
                    actions: [
                        // <Button type='link' onClick={loadParent}
                        //         key='loadParent'><EnterOutlined rotate={90}/> 返回上级</Button>,
                        <Button type='primary' onClick={addNewRecord}
                                key='0'><PlusCircleOutlined/> 新建</Button>
                    ],
                }}
                editable={{
                    onSave: saveRecord,
                    onChange: editChange,
                    onDelete: deleteRecord,
                }}
                actionRef={actionRef}
                columns={columns}
                cardBordered={true}
                params={parents}
                search={false}
                dataSource={mainData}
                request={async (
                    // params,
                    // sort,
                    // filter,
                    ) => {
                    let query = {parentId: parents[parents.length - 1].value};
                    const res1 = await FetchUtil.awaitFetch("/adminApi/param/list", query);
                    let d=[];
                    if (res1.code === 200) {
                        d= res1.data.list.map((r:any)=>{
                            r.parentId = parents.map(p=> p.value).slice(1);
                            return r
                        })
                        setMainData(d)
                    }
                    return {
                        success: true,
                        data: d
                    };
                }}
                headerTitle={<div hidden={parents.length <= 1}>
                    <Button type='link'
                            onClick={loadParent}
                            hidden={parents.length === 1}
                            key='loadParent'>
                        <ArrowLeftOutlined /> 返回上级</Button>
                </div>}
                // recordCreatorProps={{record: () => ({id: 0}),}}
            />
            <div id={"endOfTable"}></div>
            <MultiLanguageEditor ref={mlRef} />
        </MyProLayout>
    )
}

export default Params