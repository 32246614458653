import store from "store";


export default class FetchUtil {

    static awaitFetch = async (url: string, queryParam: any, method?: string) => {
        try {
            const loginInfo = store.get("login_info");
            const accessToken = loginInfo ? loginInfo.accessToken || "" : "";
            const res = await fetch(
                url,
                {
                    method: method || 'POST',
                    headers: {"Content-Type": "application/json", "accessToken": accessToken},
                    body: JSON.stringify(queryParam)
                });
            return await res.json();

        }catch (e) {
            return null;
        }
    }

    static postFetch = (url: string, queryParam: {}, callback: (s: boolean, d: any) => void) => {

        fetch(
            url,
            {
                method: 'POST',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(queryParam)
            }).then((res) => {
            if (res.status === 200) {

            } else {
                callback(false, []);
            }

        }).catch((error) => {
            callback(false, error);

        });

    }
}