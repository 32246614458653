import {LoginForm, ProCard, ProFormText} from "@ant-design/pro-components";
import MyProLayout from "../../../components/MyProLayout";
import {LockOutlined} from "@ant-design/icons";
import React from "react";
import AxiosUtil from "../../../utils/AxiosUtil";
import store from "store";
import {message} from "antd";
import {useNavigate} from "react-router";

const ChangePass = () => {

    const navigate = useNavigate()
    const onFinish = (values: any) => {
        // setLoading(true)
        let queryParams: any = {
            origPwd: values.orig_pwd,
            newPwd: values.new_pwd,
            rptPwd: values.rpt_pwd,
        }
        AxiosUtil.AxiosRequest('/authApi/login/changePwd', queryParams,
            (suc: boolean) => {
                // setLoading(false)
                if (suc) {
                    store.set('login_info', undefined);
                    message.success('密码已重置, 请重新登录').then();
                    navigate("/login", {replace: true})
                }
            })
        return "" as any
    }
    return (
        <MyProLayout >
                <ProCard boxShadow style={{width: 512, marginBlockStart: 64, marginInline: "auto"}}>
                    <LoginForm logo={undefined}
                               title={""}
                               subTitle={"请输入原密码和新密码进行修改"}
                               actions
                               message={""}
                               onFinish={onFinish}
                               submitter={{searchConfig: {submitText: '修改密码'}}}
                    >

                        <ProFormText.Password
                            name="orig_pwd"
                            fieldProps={{
                                size: 'large',
                                prefix: <LockOutlined className={'prefixIcon'}/>,
                            }}
                            placeholder={'原密码: Your password'}
                            rules={[{required: true, message: '请输入原密码！',}]}
                        />
                        <ProFormText.Password
                            name="new_pwd"
                            fieldProps={{
                                size: 'large',
                                prefix: <LockOutlined className={'prefixIcon'}/>,
                            }}
                            placeholder={'新密码: NEW password'}
                            rules={[{required: true, message: '请输入新密码！',}]}
                        />
                        <ProFormText.Password
                            name="rpt_pwd"
                            fieldProps={{
                                size: 'large',
                                prefix: <LockOutlined className={'prefixIcon'}/>,
                            }}
                            placeholder={'原密码: Re-entry new password'}
                            rules={[{required: true, message: '请再次输入密码！',}]}
                        />

                        <div style={{marginBlockEnd: 24,}}>
                            <a style={{float: 'right',}}>
                                忘记密码
                            </a>
                        </div>
                    </LoginForm>
                </ProCard>
        </MyProLayout>
    )
}
export default ChangePass