import React, {useEffect, useRef, useState} from "react";
import {Button, Space, Switch} from "antd";
import JsonView from "react18-json-view";
import 'react18-json-view/src/style.css'
import {ModalForm, ProCard, ProFormText} from "@ant-design/pro-components";
import {EditOutlined} from "@ant-design/icons";
import MyJsonEditor from "./MyJsonEditor";
import {FormInstance} from "antd/lib";


interface IProps {
    label: string | undefined,
    rawData: string | undefined,
    editable?: boolean,
    name?: string,
    formRef?: FormInstance<any>,
}

const MyRawData = (props: IProps) => {

    const editorRef = useRef();
    const [pretty, setPretty] = useState(true);
    const [base64, setBase64] = useState(false);
    const [showBase64, setShowBase64] = useState(false);
    const [base64Text, setBase64Text] = useState<string>();
    const [open, setOpen] = useState(false);
    const [jsonText, setJsonText] = useState<any>();
    // const [editText, setEditText] = useState<string>();
    // const dataString = props.rawData;

    // React.useEffect(() => {
    //     document.querySelectorAll("pre code").forEach(block => {
    //         try {
    //             hljs.highlightBlock(block as HTMLElement);
    //         } catch (e) {
    //             console.log(e);
    //         }
    //     });
    // });

    let editText: string;
    useEffect(() => {
        setBase64(false);
        if (props.rawData !== undefined && props.rawData !== null && props.rawData.trim() !== "") {
            try {
                let dataJson = JSON.parse(props.rawData);
                setJsonText(dataJson);
                setShowBase64(false);
            } catch {
                setJsonText(undefined)
                if ((props.rawData.startsWith("b'") && props.rawData.endsWith("'"))) {
                    let text = props.rawData?.substring(2, props.rawData?.length - 1);
                    text = atob(text)
                    setBase64Text(text);
                    setShowBase64(true);
                } else {
                    try {
                        let text = atob(props.rawData)
                        setBase64Text(text);
                        setShowBase64(true);
                    } catch {
                        setBase64Text(undefined);
                        setShowBase64(false);
                    }
                }
            }
        } else {
            setJsonText("");
            setShowBase64(false);
        }
    }, [props])

    /**
     *
     * @param checked
     */
    const onChange = (checked: boolean) => {
        setPretty(checked);
    };
    const onChangeBase64 = (checked: boolean) => {
        setBase64(checked);
        if (checked) {
            if (base64Text) {
                setJsonText(JSON.parse(base64Text));
            }
        } else {
            setJsonText(undefined);
        }
    }
    const submitForm = async () => {
        console.log(editText);
        let dataJson = JSON.parse(editText);
        setJsonText(dataJson);
        setOpen(false);
        if (props.name) {
            props.formRef?.setFieldsValue({[props.name]: dataJson});
        }
    }
    return (
        <ProCard title={<Space size={24}>
            <ProFormText name={props.name} hidden/>
            {props.label}
            <Switch defaultChecked onChange={onChange} checkedChildren="Pretty" unCheckedChildren="Raw"/>
            {showBase64 && <>
                Base64转义
                <Switch onChange={onChangeBase64} checked={base64}/>
            </>}
            {props.editable &&
                <Button type={"link"}
                    // onClick={() => (editorRef.current as any).openEditor()}
                        onClick={() => setOpen(true)}
                >
                    <EditOutlined/>编辑JSON
                </Button>
            }
        </Space>}
                 bordered
                 headerBordered
            // boxShadow
            // collapsible
        >
            <div style={{wordWrap: "break-word", maxHeight: "60vh", overflow: "scroll"}}>
                {(pretty && jsonText)
                    ? <JsonView src={jsonText} theme={"atom"}/>
                    : props.rawData
                }
            </div>

            <ModalForm open={open}
                       onFinish={submitForm}
                       modalProps={{
                           onCancel: () => setOpen(false),
                           // forceRender: true,
                       }}>
                <MyJsonEditor ref={editorRef}
                              content={{text: jsonText ? JSON.stringify(jsonText) : ""}}
                              readOnly={false}
                              onChange={(e: any) => {
                                  console.log(e.text);
                                  editText = e.text;
                              }}
                />
            </ModalForm>
        </ProCard>
    );
}

export default MyRawData;