import {Button, Col, Drawer, Form, Input, message, Row, Spin} from "antd";
import {GlobalOutlined, SaveOutlined} from "@ant-design/icons";
import React, {forwardRef, useImperativeHandle, useState} from "react";
import AxiosUtil from "../utils/AxiosUtil";
import {useAppContext} from "../AppContaxt";

const MultiLanguageEditor = (props : any, ref: any) => {

    const [formRef] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [editingType, setEditingType] = useState<any>();

    const {appState, updateAppState} = useAppContext();
    const langData: any = appState.langData;

    useImperativeHandle(ref, () => {
        return {
            openEditor: (r: any) => openEditor(r)
        }
    });

    const openEditor = (r: any) => {
        setLoading(true);
        setEditingType(r);
        formRef.resetFields();
        AxiosUtil.AxiosRequest("/adminApi/language/multiList", r,
            (suc, data) => {
                data.list?.forEach((r:any)=>{
                    formRef.setFieldsValue({ [`lang${r.langCode}`] : r.langContent })
                })
                setLoading(false);
            })
        setOpenForm(true);
    }

    const closeMl = () => {
        setOpenForm(false);
    }

    const submitForm = (values : any)=> {
        console.log(values);
        const langs : any[] = [];
        langData?.forEach((r:any)=>{
            langs.push({
                itemType:editingType.type,
                itemId:editingType.id,
                langCode:r.langCode,
                langContent: values[`lang${r.langCode}`] || ""
            })
        })
        console.log(langs);
        AxiosUtil.AxiosRequest("/adminApi/language/multiEdit", langs,
            (suc: boolean, data: any) => {
                if (suc) {
                    message.success("保存成功！").then();
                    setOpenForm(false);
                    if (props.callback) {
                        props.callback();
                    }
                }else{
                    message.error("保存失败！ " + data.msg).then();
                }
            })
    }


    return (
        <Drawer placement="right"
                width={666}
                open={openForm}
                onClose={closeMl}
                title={<Row>
                    <Col span={11}><GlobalOutlined/> 多语言设置</Col>
                    <Col span={11} className="right">
                        <Button type={"primary"} onClick={()=>formRef.submit()}><SaveOutlined/> 保 存</Button>
                    </Col>
                </Row>}>
            <Form id="editorForm"
                  layout="horizontal"
                  colon={false}
                  onFinish={submitForm}
                  labelCol={{span: 4, offset: 0}}
                  form={formRef}
                  // onValuesChange={(changedValues, values) => props.changeCallback(true, values)}
            >
                <Row>
                    {langData?.map((r:any) =>
                        <Col span={24} key={r.key}>
                            <Form.Item name={`lang${r.value}`}
                                       label={r.label}>
                                <Input placeholder={''} allowClear/>
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            </Form>
        </Drawer>
    )
}

export default forwardRef(MultiLanguageEditor);